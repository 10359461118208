import axios from 'axios';
import * as types from './types';
import { returnErrors } from './errorActions';
import { GarageStall } from '../../types/interfaces';
import History from '../../components/common/History';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';

export const setGarageStallLoading = () => ({ type: types.GARAGE_STALLS_LOADING });

export const getAllGarageStalls = () => (dispatch: Function, getState: Function) => {
  dispatch(setGarageStallLoading());

  axios
    .get(configs.url.API_URL + '/garage-stalls', authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_ALL_GARAGE_STALLS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const getGarageStall = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setGarageStallLoading());
  axios
    .get(`${configs.url.API_URL}/garage-stall/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_GARAGE_STALL,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const addGarageStall = (garageStall: GarageStall) => (dispatch: Function, getState: Function) => {
  dispatch(setGarageStallLoading());
  axios
    .post(configs.url.API_URL + '/garage-stall', garageStall, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.ADD_GARAGE_STALL,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const updateGarageStall = (garageStall: GarageStall) => (dispatch: Function, getState: Function) => {
  dispatch(setGarageStallLoading());
  axios
    .put(configs.url.API_URL + '/garage-stall', garageStall, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.UPDATE_GARAGE_STALL,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const deleteGarageStall = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setGarageStallLoading());
  axios
    .delete(`${configs.url.API_URL}/garage-stall/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.DELETE_GARAGE_STALL,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};
