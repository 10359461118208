import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import * as builderActions from '../../redux/actions/builderActions';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import {
  BuilderReduxProps,
  BuilderList,
  Builder,
  Target,
} from '../../types/interfaces';


const BuilderPage = ({ builders, actions }: BuilderList, getState: Function) => {
  useEffect(() => {

    const url = configs.url.API_URL + '/cities';
    axios.get(url, authHeader(getState))
      .then(response => {
        setCitiesState(JSON.parse(JSON.stringify(response.data.data)));
        setLoadingState(false);
        console.log(response.data);
      });

    actions.getAllBuilders();
  }, [actions.getAllBuilders]);

  const defaultState = {
    id: 0, name: '', address: '', city: '', state: '', zipcode: '', contactName: '', email: '', phone: '', status: 1,
  };

  const [formData, setFormData] = useState(defaultState);
  const [submitted, setSubmitted] = useState(false);
  const [citiesData, setCitiesState] = useState([]);
  const [cstatesData, setCStatesState] = useState([]);
  const [loading, setLoadingState] = useState(true);

  const clearData = () => {
    setSubmitted(false);
    setFormData({ ...defaultState });
  };

  const { builders: buildersData } = builders;
  const handleDelete = (e: any, id: number) => {
    e.preventDefault();
    actions.deleteBuilder(id);
  };

  const handleEdit = (e: any, builder: Builder) => {
    e.preventDefault();
    setFormData({ ...defaultState, ...builder });
  };

  const onFormChange = (e: Target) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onStatusChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: parseInt(e.target.value, 10) });

    if (e.target.name == 'city') {
      const url = configs.url.API_URL + '/states/' + e.target.value;
      axios.get(url, authHeader(getState))
        .then(response => {
          setCStatesState(JSON.parse(JSON.stringify(response.data)));
          setLoadingState(false);
          console.log(response.data);
        });
    }

    console.log(e.target.name);
    console.log(formData);
  };

  const getCityName = (cityId: any) => {
    const city = citiesData.filter((singleCity: any) => singleCity.id === parseInt(cityId, 10))
      .map((city: any) => city.name);
    return (
      <>
        {city.length > 0 ? city : ''}
      </>
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSubmitted(true);
    if (formData.name && formData.city && formData.state && formData.phone) {
      if (!formData.id) {
        actions.addBuilder(formData);
      } else {
        actions.updateBuilder(formData);
      }
    }
  };

  return (
    <>
      <div className="clear pad-40" />
      <div className="container">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-3">
                  <h3>
                    Builders
                    <br />
                    <small>Manage Builders & details</small>
                  </h3>
                </div>
                <div className="col-md-9 mt-20 text-right">
                  {/* <button type="button" className="btn btn-info mr-5">
                    <i className="fas fa-arrow-circle-left mr-5" />
                    Return to Dashboard
                  </button> */}
                  {/* <button type="button" className="btn btn-primary">
                    <i className="fas fa-save mr-5" />
                    Save
                  </button> */}
                </div>
              </div>
            </div>

            <div className="card-body">
              <form className="form-horizontal" onSubmit={(e) => handleSubmit(e)}>
                <h4 className="text_blue">
                  Builder Details
                </h4>
                <div className="clear pad-5" />
                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Name :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        name="name"
                        value={formData.name || ''}
                        onChange={(e) => onFormChange(e)}
                        className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Address :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        name="address"
                        value={formData.address || ''}
                        onChange={(e) => onFormChange(e)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      City :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      {/* <input
                        type="text"
                        name="city"
                        value={formData.city || ''}
                        onChange={(e) => onFormChange(e)}
                        className={`form-control ${submitted && !formData.city ? 'ap-required' : ''}`}
                      /> */}
                      <select
                        name="city"
                        value={formData.city || ''}
                        onChange={(e) => onStatusChange(e)}
                        className={`form-control ${submitted && !formData.city ? 'ap-required' : ''}`}
                      >
                        <option value={0}>select...</option>
                        {!loading ? citiesData.map((jd: any, i: any) => (
                          <option key={i} value={jd?.id}>{jd?.name}</option>
                        )) : (
                            <option value={0}>select...</option>
                          )}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      State :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      {/* <input
                        type="text"
                        name="state"
                        value={formData.state || ''}
                        onChange={(e) => onFormChange(e)}
                        className={`form-control ${submitted && !formData.state ? 'ap-required' : ''}`}
                      /> */}
                      <select
                        name="state"
                        value={formData.state || ''}
                        onChange={(e) => onStatusChange(e)}
                        className={`form-control ${submitted && !formData.state ? 'ap-required' : ''}`}
                      >
                        <option value={0}>select...</option>
                        {!loading ? cstatesData.map((jd: any, i: any) => (
                          <option key={i} value={jd?.id}>{jd?.name}</option>
                        )) : (
                            <option value={0}>select...</option>
                          )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Zipcode :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        name="zipcode"
                        value={formData.zipcode || ''}
                        onChange={(e) => onFormChange(e)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Contact Name :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        name="contactName"
                        value={formData.contactName || ''}
                        onChange={(e) => onFormChange(e)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Email :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        name="email"
                        value={formData.email || ''}
                        onChange={(e) => onFormChange(e)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Phone :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone || ''}
                        onChange={(e) => onFormChange(e)}
                        className={`form-control ${submitted && !formData.phone ? 'ap-required' : ''}`}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Status :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="status"
                        value={formData.status.toString() || '1'}
                        onChange={(e) => onStatusChange(e)}
                      >
                        <option value={1}>Active</option>
                        <option value={0}>In-Active</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-20 text-right">
                    <button type="button" className="btn btn-info mr-5" onClick={() => clearData()}>
                      {/* <i className="fas fa-arrow-circle-left mr-5" /> */}
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      <i className="fas fa-save mr-5" />
                      Save
                    </button>
                  </div>
                </div>

                <hr />
                {/* <div className="clear pad-15" /> */}

                {/* <div className="row">
                  <div className="col-md-6 col-md-offset-3">
                    <div className="row">
                      <div className="col-sm-6 mb-5">
                        <button type="button" className="btn btn-info btn-block btn-lg">
                          <i className="fas fa-arrow-circle-left mr-5" />
                          Return to Schedule
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button type="submit" className="btn btn-primary btn-block btn-lg">
                          <i className="fas fa-save mr-5" />
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </form>

              <h4 className="text_blue">
                Builders
              </h4>
              <div className="clear pad-5" />

              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      {/* <th>
                        <input type="checkbox" />
                      </th> */}
                      <th>
                        Name
                      </th>
                      <th>
                        Address
                      </th>
                      <th>
                        City
                      </th>
                      <th>
                        State
                      </th>
                      <th>
                        Zipcode
                      </th>
                      <th>
                        Phone
                      </th>
                      <th>
                        Contact Name
                      </th>
                      <th>
                        Status
                      </th>
                      <th>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {buildersData.length > 0 ? buildersData.map((builder) => (
                      <tr key={builder.id}>
                        {/* <td>
                          <input type="checkbox" />
                        </td> */}
                        <td>
                          <strong>{builder.name}</strong>
                        </td>
                        <td>
                          <strong>{builder.address}</strong>
                        </td>
                        <td>
                          <strong>{getCityName(builder.city)}</strong>
                        </td>
                        <td>
                          <strong>{builder.state}</strong>
                        </td>
                        <td>
                          <strong>{builder.zipcode}</strong>
                        </td>
                        <td>
                          <strong>{builder.phone}</strong>
                        </td>
                        <td>
                          <strong>{builder.contactName}</strong>
                        </td>
                        <td>
                          {builder.status === 1 ? 'Active' : 'In-Active'}
                        </td>
                        <td>
                          <a
                            href="#"
                            title="Edit"
                            className="text_grey_d"
                            onClick={(e) => handleEdit(e, builder)}
                          >
                            <i className="fa fa-edit fa-lg" />
                          </a>
                          <a
                            href="#"
                            title="Edit"
                            className="text_red"
                            onClick={(e) => handleDelete(e, builder.id)}
                          >
                            <i className="fa fa-times-circle fa-lg" />
                          </a>
                        </td>
                      </tr>
                    )) : (
                        <tr>
                          <td colSpan={10} className="text-center">
                            No record found.
                        </td>
                        </tr>
                      )}
                  </tbody>
                </table>
                {buildersData.length < 1}
                {/* <ul className="pagination center-block">
                  <li><a href="#">«</a></li>
                  <li><a href="#">1</a></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#">4</a></li>
                  <li><a href="#">5</a></li>
                  <li><a href="#">»</a></li>
                </ul> */}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

BuilderPage.propTypes = {
  // builders: PropTypes.object.isRequired,
  // actions: PropTypes.func.isRequired
};


const mapStateToProps = (state: BuilderReduxProps) => ({
  builders: state.builders,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    getAllBuilders: bindActionCreators(builderActions.getAllBuilders, dispatch),
    getBuilder: bindActionCreators(builderActions.getBuilder, dispatch),
    addBuilder: bindActionCreators(builderActions.addBuilder, dispatch),
    updateBuilder: bindActionCreators(builderActions.updateBuilder, dispatch),
    deleteBuilder: bindActionCreators(builderActions.deleteBuilder, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BuilderPage);
