import axios from 'axios';
import * as types from './types';
import { returnErrors } from './errorActions';
import { DeliveredBy } from '../../types/interfaces';
import History from '../../components/common/History';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';


export const setDeliveredByLoading = () => ({ type: types.DELIVERED_BY_LOADING });

export const getAllDeliveredBy = () => (dispatch: Function, getState: Function) => {
  dispatch(setDeliveredByLoading());

  axios
    .get(configs.url.API_URL + '/delivered-by', authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_ALL_DELIVERED_BY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const getDeliveredBy = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setDeliveredByLoading());
  axios
    .get(configs.url.API_URL + `/delivered-by/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_DELIVERED_BY,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const addDeliveredBy = (deliveredBy: DeliveredBy) => (dispatch: Function, getState: Function) => {
  dispatch(setDeliveredByLoading());
  axios
    .post(configs.url.API_URL + '/delivered-by', deliveredBy, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.ADD_DELIVERED_BY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const updateDeliveredBy = (deliveredBy: DeliveredBy) => (dispatch: Function, getState: Function) => {
  dispatch(setDeliveredByLoading());
  axios
    .put(configs.url.API_URL + '/delivered-by', deliveredBy, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.UPDATE_DELIVERED_BY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const deleteDeliveredBy = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setDeliveredByLoading());
  axios
    .delete(`${configs.url.API_URL}/delivered-by/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.DELETE_DELIVERED_BY,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};
