import axios from 'axios';
import * as types from './types';
import { returnErrors } from './errorActions';
import { City } from '../../types/interfaces';
import History from '../../components/common/History';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';


export const setCitiesLoading = () => ({ type: types.CITIES_LOADING });

export const getAllCities = () => (dispatch: Function, getState: Function) => {
  dispatch(setCitiesLoading());

  axios
    .get(configs.url.API_URL + '/cities', authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_ALL_CITIES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const getCity = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setCitiesLoading());
  axios
    .get(`${configs.url.API_URL}/city/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_CITY,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const addCity = (city: City) => (dispatch: Function, getState: Function) => {
  dispatch(setCitiesLoading());
  axios
    .post(configs.url.API_URL + '/city', city, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.ADD_CITY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const updateCity = (city: City) => (dispatch: Function, getState: Function) => {
  dispatch(setCitiesLoading());
  axios
    .put(configs.url.API_URL + '/city', city, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.UPDATE_CITY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const deleteCity = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setCitiesLoading());
  axios
    .delete(`${configs.url.API_URL}/city/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.DELETE_CITY,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};
