import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import * as garageFinishActions from '../../redux/actions/garageFinishActions';
import PropTypes from 'prop-types';
import {
  GarageFinishReduxProps,
  GarageFinishList,
  GarageFinish,
  Target,
} from '../../types/interfaces';


const GarageFinishPage = ({ garageFinishes, actions }: GarageFinishList) => {
  useEffect(() => {
    actions.getAllGarageFinishes();
  }, [actions.getAllGarageFinishes]);

  const defaultState = {
    id: 0, name: '', status: 1,
  };

  const [formData, setFormData] = useState(defaultState);
  const [submitted, setSubmitted] = useState(false);

  const clearData = () => {
    setSubmitted(false);
    setFormData({ ...defaultState });
  };

  const { garageFinishes: garageFinishesData } = garageFinishes;
  const handleDelete = (e: any, id: number) => {
    e.preventDefault();
    actions.deleteGarageFinish(id);
  };

  const handleEdit = (e: any, garageFinish: GarageFinish) => {
    e.preventDefault();
    setFormData({ ...defaultState, ...garageFinish });
  };

  const onFormChange = (e: Target) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onStatusChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: parseInt(e.target.value, 10) });
    console.log(formData);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSubmitted(true);
    if (formData.name) {
      if (!formData.id) {
        actions.addGarageFinish(formData);
      } else {
        actions.updateGarageFinish(formData);
      }
    }
  };

  return (
    <>
      <div className="clear pad-40" />
      <div className="container">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-3">
                  <h3>
                    Garage Finish
                    <br />
                    <small>Manage Garage Finish & details</small>
                  </h3>
                </div>
                <div className="col-md-9 mt-20 text-right">
                  {/* <button type="button" className="btn btn-info mr-5">
                    <i className="fas fa-arrow-circle-left mr-5" />
                    Return to Dashboard
                  </button> */}
                  {/* <button type="button" className="btn btn-primary">
                    <i className="fas fa-save mr-5" />
                    Save
                  </button> */}
                </div>
              </div>
            </div>

            <div className="card-body">
              <form className="form-horizontal" onSubmit={(e) => handleSubmit(e)}>
                <h4 className="text_blue">
                  GarageFinish Details
                </h4>
                <div className="clear pad-5" />
                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Name :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        name="name"
                        value={formData.name || ''}
                        onChange={(e) => onFormChange(e)}
                        className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Status :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="status"
                        value={formData.status.toString() || '1'}
                        onChange={(e) => onStatusChange(e)}
                      >
                        <option value={1}>Active</option>
                        <option value={0}>In-Active</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-20 text-right">
                    <button type="button" className="btn btn-info mr-5" onClick={() => clearData()}>
                      {/* <i className="fas fa-arrow-circle-left mr-5" /> */}
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      <i className="fas fa-save mr-5" />
                      Save
                    </button>
                  </div>
                </div>

                <hr />
                {/* <div className="clear pad-15" /> */}

                {/* <div className="row">
                  <div className="col-md-6 col-md-offset-3">
                    <div className="row">
                      <div className="col-sm-6 mb-5">
                        <button type="button" className="btn btn-info btn-block btn-lg">
                          <i className="fas fa-arrow-circle-left mr-5" />
                          Return to Schedule
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button type="submit" className="btn btn-primary btn-block btn-lg">
                          <i className="fas fa-save mr-5" />
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </form>

              <h4 className="text_blue">
                Garage Finishes
              </h4>
              <div className="clear pad-5" />

              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      {/* <th>
                        <input type="checkbox" />
                      </th> */}
                      <th>
                        Name
                      </th>
                      <th>
                        Status
                      </th>
                      <th>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {garageFinishesData.length > 0 ? garageFinishesData.map((garageFinish) => (
                      <tr key={garageFinish.id}>
                        {/* <td>
                          <input type="checkbox" />
                        </td> */}
                        <td>
                          <strong>{garageFinish.name}</strong>
                        </td>
                        <td>
                          {garageFinish.status === 1 ? 'Active' : 'In-Active'}
                        </td>
                        <td>
                          <a
                            href="#"
                            title="Edit"
                            className="text_grey_d"
                            onClick={(e) => handleEdit(e, garageFinish)}
                          >
                            <i className="fa fa-edit fa-lg" />
                          </a>
                          <a
                            href="#"
                            title="Edit"
                            className="text_red"
                            onClick={(e) => handleDelete(e, garageFinish.id)}
                          >
                            <i className="fa fa-times-circle fa-lg" />
                          </a>
                        </td>
                      </tr>
                    )) : (
                        <tr>
                          <td colSpan={5} className="text-center">
                            No record found.
                        </td>
                        </tr>
                      )}
                  </tbody>
                </table>
                {garageFinishesData.length < 1}
                {/* <ul className="pagination center-block">
                  <li><a href="#">«</a></li>
                  <li><a href="#">1</a></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#">4</a></li>
                  <li><a href="#">5</a></li>
                  <li><a href="#">»</a></li>
                </ul> */}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GarageFinishPage.propTypes = {
  // garageFinishes: PropTypes.object.isRequired,
  // actions: PropTypes.func.isRequired
};


const mapStateToProps = (state: GarageFinishReduxProps) => ({
  garageFinishes: state.garageFinishes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    getAllGarageFinishes: bindActionCreators(garageFinishActions.getAllGarageFinishes, dispatch),
    getGarageFinish: bindActionCreators(garageFinishActions.getGarageFinish, dispatch),
    addGarageFinish: bindActionCreators(garageFinishActions.addGarageFinish, dispatch),
    updateGarageFinish: bindActionCreators(garageFinishActions.updateGarageFinish, dispatch),
    deleteGarageFinish: bindActionCreators(garageFinishActions.deleteGarageFinish, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GarageFinishPage);
