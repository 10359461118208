import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Header from './components/common/Header';
import History from './components/common/History';
import PrivateRoute from './components/common/PrivateRoute';
import PublicRoute from './components/common/PublicRoute';


import HomePage from './components/home/HomePage';
import LoginPage from './components/auth/LoginPage';
import PageNotFound from './components/PageNotFound';


import ArticleList from './components/ArticleList';
import UserPage from './components/UserPage';
import CityPage from './components/city/CityPage';
import RatePlanPage from './components/rate-plan/RatePlanPage';
import InvoicePage from './components/invoices/InvoicePage';
import InvoiceallPage from './components/invoiceall/InvoiceallPage';
import DeliveredByPage from './components/delivered-by/DeliveredByPage';
import HouseTypePage from './components/house-type/HouseTypePage';

import GarageFinishPage from './components/garage-finish/GarageFinishPage';
import GarageStallPage from './components/garage-stall/GarageStallPage';
import OptionPage from './components/option/OptionPage';
import VaultPage from './components/vault/VaultPage';
import BuilderPage from './components/builder/BuilderPage';
import CeilingFinish from './components/ceiling-finish/CeilingFinishPage';
import JobOrderPage from './components/job-order/JobOrderPage';
import SchedulePage from './components/schedule/SchedulePage';

// for every call, get user details
import store from './redux/store';
import { loadUser } from './redux/actions/authActions';


// import 'bootstrap/dist/css/bootstrap.min.css';
// import './Bootstrap.css';
// import './Style.css';
import './App.css';



const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  console.log('testings');

  return (
    <>
      <Header />
      <Router history={History}>
        <Switch>
          <PrivateRoute exact path="/" component={HomePage} />
          {/* <Route exact path="/" component={HomePage} /> */}
          <PublicRoute path="/login" component={LoginPage} />
          <Route path="/users" component={UserPage} />
          <Route path="/articles" component={ArticleList} />
          <Route path="/cities" component={CityPage} />
          <Route path="/rate-plan" component={RatePlanPage} />
          <Route path="/invoices" component={InvoicePage} />
          <Route path="/invoiceall/:job_id?" component={InvoiceallPage} />
          <Route path="/delivered-by" component={DeliveredByPage} />
          <Route path="/house-types" component={HouseTypePage} />
          <Route path="/garage-finishes" component={GarageFinishPage} />
          <Route path="/garage-stalls" component={GarageStallPage} />
          <Route path="/options" component={OptionPage} />
          <Route path="/vaults" component={VaultPage} />
          <Route path="/builders" component={BuilderPage} />
          <Route path="/ceiling-finishes" component={CeilingFinish} />
          <Route path="/job-orders/:id?" component={JobOrderPage} />
          <Route path="/schedules" component={SchedulePage} />


          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
