import axios from 'axios';
import * as types from './types';
import { returnErrors } from './errorActions';
import { Option } from '../../types/interfaces';
import History from '../../components/common/History';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';


export const setOptionLoading = () => ({ type: types.OPTIONS_LOADING });

export const getAllOptions = () => (dispatch: Function, getState: Function) => {
  dispatch(setOptionLoading());

  axios
    .get(configs.url.API_URL + '/options', authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_ALL_OPTIONS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const getOption = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setOptionLoading());
  axios
    .get(`${configs.url.API_URL}/option/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_OPTION,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const addOption = (option: Option) => (dispatch: Function, getState: Function) => {
  dispatch(setOptionLoading());
  axios
    .post(configs.url.API_URL + '/option', option, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.ADD_OPTION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const updateOption = (option: Option) => (dispatch: Function, getState: Function) => {
  dispatch(setOptionLoading());
  axios
    .put(configs.url.API_URL + '/option', option, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.UPDATE_OPTION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const deleteOption = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setOptionLoading());
  axios
    .delete(`${configs.url.API_URL}/option/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.DELETE_OPTION,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};
