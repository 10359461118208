import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from 'react-bootstrap-date-picker';
import moment from 'moment'
// import { useReactToPrint } from 'react-to-print';
import * as JobOrderActions from '../../redux/actions/jobOrderActions';
import * as BuilderActions from '../../redux/actions/builderActions';
import * as HouseTypeActions from '../../redux/actions/houseTypeActions';
import * as UserActions from '../../redux/actions/userActions';
import * as CityActions from '../../redux/actions/cityActions';
import * as DeliveredByActions from '../../redux/actions/deliveredByActions';
import * as GarageStallActions from '../../redux/actions/garageStallActions';
import * as CeilingFinishActions from '../../redux/actions/ceilingFinishActions';
import * as GarageFinishActions from '../../redux/actions/garageFinishActions';
import * as VaultActions from '../../redux/actions/vaultActions';
import * as OptionActions from '../../redux/actions/optionActions';
import * as BillingItemActions from '../../redux/actions/billingItemActions';
import * as HouseLevelTypeActions from '../../redux/actions/houseLevelTypeActions';
// import Print from '../common/Print';

import PropTypes from 'prop-types';
import {
  JobOrderReduxProps,
  JobOrderList,
  JobOrder,
  Target,
} from '../../types/interfaces';


const JioPrint = ({
  jobOrders,
  builders,
  users,
  houseTypes,
  cities,
  deliveredBy,
  garageStalls,
  ceilingFinishes,
  garageFinishes,
  vaults,
  options,
  billingItems,
  houseLevelTypes,
  actions
}: JobOrderList) => {

  const { id } = useParams();

  // props.id
  // console.log('soooooo+++++++++++=', id);
  // console.log('---------', jobOrders);
  // console.log(props.id);

  useEffect(() => {
    // if(id !== undefined) {
    // console.log(jobOrders);
    const jid: number = id !== undefined ? +id : 0;
    //   actions.getJobOrder(jid);
    //   console.log('yes working');
    // }

    // actions.getAllJobOrders();
    // actions.getAllBuilders();
    // actions.getUsers();
    // actions.getAllHouseTypes();
    // actions.getAllCities();
    // actions.getAllDeliveredBy();
    // actions.getAllGarageStalls();
    // actions.getAllCeilingFinishes();
    // actions.getAllGarageFinishes();
    // actions.getAllVaults();
    // actions.getAllOptions();
    // actions.getJobOrder(jid);
  }, [
    // actions.getAllJobOrders,
    // actions.getAllBuilders,
    // actions.getUsers,
    // actions.getAllHouseTypes,
    // actions.getAllCities,
    // actions.getAllDeliveredBy,
    // actions.getAllGarageStalls,
    // actions.getAllCeilingFinishes,
    // actions.getAllGarageFinishes,
    // actions.getAllVaults,
    // actions.getAllOptions,
    // actions.getJobOrder,
  ]
  );

  // // interface ParamTypes {
  // //   id: string
  // // }
  // let { id } = useParams();
  // console.log(id);
  // // var jid: number = +id;
  // // console.log(this.props.params.id);

  const setFormDataState = () => {
    if (jobOrders.activeJobOrder.id !== undefined) {
      setFormData({ ...defaultState, ...jobOrders.activeJobOrder });
    }

  }
  const jid: number = id !== undefined ? +id : 0;
  useEffect(() => {
    // console.log(typeof id);
    // const jid = parseInt(id);
    // const jid: number = parseInt(id, 10);
    // if(id !== undefined) {
    //   const jid: number = +id;
    // actions.getJobOrder(jid);
    setFormDataState();
    //   console.log('yes working');
    // }
  }, [
    jobOrders.activeJobOrder,
    // actions.getJobOrder
  ]
  );

  // console.log(id);
  const defaultState = {
    id: 0,
    builderId: 0,
    supervisorId: 0,
    name: '',
    houseTypeId: 0,
    address: '',
    cityId: 0,

    deliveryDate: '',
    deliveryTime: '',
    deliveredById: 0,

    startDate: '',
    closeDate: '',
    paintStartDate: '',
    garageStallId: 0,
    walkthroughDate: '',
    ceilingFinishId: 0,
    garageFinishId: 0,
    electric: 0,
    heat: 0,
    basement: 0,


    up58: 0,
    upHs: 0,
    up12: 0,
    up5412: 0,
    up5458: 0,
    main58: 0,
    mainHs: 0,
    main12: 0,
    main5412: 0,
    main5458: 0,
    l358: 0,
    l3Hs: 0,
    l312: 0,
    l35412: 0,
    l35458: 0,
    g58: 0,
    gHs: 0,
    g12: 0,
    g54: 0,
    g5412: 0,
    g5458: 0,
    house4x12: 0,
    house4x12o: 0,
    house54: 0,
    garage4x12: 0,
    garage4x12o: 0,
    garage54: 0,

    houseLevels: [],

    options: [],
    additionalInfo: '',

    status: 1,
  };

  const [formData, setFormData] = useState(defaultState);
  const [submitted, setSubmitted] = useState(false);

  const clearData = () => {
    setSubmitted(false);
    setFormData({ ...defaultState });
  };

  const handleDelete = (e: any, id: number) => {
    e.preventDefault();
    actions.deleteJobOrder(id);
  };

  const handleEdit = (e: any, jobOrder: JobOrder) => {
    e.preventDefault();
    setFormData({ ...defaultState, ...jobOrder });
  };

  const onFormChange = (e: Target) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCheckboxChange = (e: Target) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked ? 1 : 0 });
  };

  const onDateChange = (date: any, name: string) => {
    setFormData({ ...formData, [name]: moment(date).format('YYYY-MM-DD') });
  }

  const onMultiSelectChange = (value: any, name: string) => {
    if (value === null) {
      setFormData({ ...formData, [name]: [] });
    } else {
      setFormData({ ...formData, [name]: [...value] });
    }
  };

  const onStatusChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: parseInt(e.target.value, 10) });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSubmitted(true);
    if (formData.name) {
      if (!formData.id) {
        actions.addJobOrder(formData);
      } else {
        actions.updateJobOrder(formData);
      }
    }
  };

  const getBuilderName = (builderId: any, buildersData: any) => {
    const builder = buildersData.filter((singleBuilder: any) => singleBuilder.id === builderId);
    const builderName = builder.length ? builder[0].name : '';
    return (
      <>
        {builderName}
      </>
    );
  };

  const getHouseTypeName = (houseTypeId: any, houseTypesData: any) => {
    const houseType = houseTypesData.filter((singleHouseType: any) => singleHouseType.id === houseTypeId);
    const houseTypeName = houseType.length ? houseType[0].name : '';
    return (
      <>
        {houseTypeName}
      </>
    );
  };

  const getDeliveredByName = (deliveredById: any, deliveredByData: any) => {
    const deliveredBy = deliveredByData.filter((singleDeliveredBy: any) => singleDeliveredBy.id === deliveredById);
    const deliveredByName = deliveredBy.length ? deliveredBy[0].name : '';
    return (
      <>
        {deliveredByName}
      </>
    );
  };

  const getSupervisorName = (supervisorId: any, usersData: any) => {
    const supervisor = usersData.filter((singleUser: any) => singleUser.id === supervisorId);
    const supervisorName = supervisor.length ? supervisor[0].name : '';
    return (
      <>
        {supervisorName}
      </>
    );
  };

  const getCityName = (cityId: any, citiesData: any) => {
    const city = citiesData.filter((singleCity: any) => singleCity.id === cityId);
    const cityName = city.length ? city[0].name : '';
    return (
      <>
        {cityName}
      </>
    );
  };

  const getGarageStallName = (garageStallId: any, garageStallsData: any) => {
    const garageStall = garageStallsData.filter((singleGarageStall: any) => singleGarageStall.id === garageStallId);
    const garageStallName = garageStall.length ? garageStall[0].name : '';
    return (
      <>
        {garageStallName}
      </>
    );
  };

  const getCeilingFinishName = (ceilingFinishId: any, ceilingFinishesData: any) => {
    const ceilingFinish = ceilingFinishesData.filter((singleCeilingFinish: any) => singleCeilingFinish.id === ceilingFinishId);
    const ceilingFinishName = ceilingFinish.length ? ceilingFinish[0].name : '';
    return (
      <>
        {ceilingFinishName}
      </>
    );
  };

  const getGarageFinishName = (garageFinishId: any, garageFinishesData: any) => {
    const garageFinish = garageFinishesData.filter((singleGarageFinish: any) => singleGarageFinish.id === garageFinishId);
    const garageFinishName = garageFinish.length ? garageFinish[0].name : '';
    return (
      <>
        {garageFinishName}
      </>
    );
  };

  const { jobOrders: jobOrdersData } = jobOrders;
  const { builders: buildersData } = builders;
  const { users: usersData } = users;
  const { houseTypes: houseTypesData } = houseTypes;
  const { cities: citiesData } = cities;
  const { deliveredBy: deliveredByData } = deliveredBy;
  const { garageStalls: garageStallsData } = garageStalls;
  const { ceilingFinishes: ceilingFinishesData } = ceilingFinishes;
  const { garageFinishes: garageFinishesData } = garageFinishes;
  const { vaults: vaultsData } = vaults;
  const { options: optionsData } = options;
  const { billingItems: billingItemsData } = billingItems;
  const { houseLevelTypes: houseLevelTypesData } = houseLevelTypes;

  // const componentRef:any = useRef();

  // const handlePrintClick = () => {
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  // const handlePrint = 'test';
  // console.log('yes');
  // return handlePrint;
  // };
  // const Example = () => {


  // return (
  //   <div>
  //     <ComponentToPrint ref={componentRef} />
  //     <button onClick={handlePrint}>Print this out!</button>
  //   </div>
  // );
  // };

  return (
    <>
      <div style={{ maxWidth: '1200', width: '100%', margin: 'auto' }}>
        <div style={{ textAlign: 'center', borderBottom: '#000 1px solid', padding: '5px' }}>
          <h2 style={{ textAlign: 'center' }}>Schoenberger Drywall, Inc.</h2>
          <p style={{ textAlign: 'center', fontSize: '20px' }}>17180 Adelmann St. SE<br />Prior Lake, MN 55372<br />Phone: 952-447-1078<br />
              Fax: 952-447-1058</p>
        </div>
        <div style={{ borderBottom: '#000 1px solid', padding: '5px' }}>
          <h3 style={{ textAlign: 'center' }}>JOB INITIATION ORDER</h3>
          <h3 style={{ textAlign: 'left', fontWeight: 500, color: '#F33' }}>Builders Details:</h3>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tr>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F', width: '23%' }}>Builder: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000' }}>
                {getBuilderName(formData.builderId, buildersData)}
              </td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>Supervisor: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000', width: '25%' }}>
                {getSupervisorName(formData.supervisorId, usersData)}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F', width: '23%' }}>H/O Name: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000' }}>
                {formData.name || ''}
              </td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>House Type: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000', width: '25%' }}>
                {getHouseTypeName(formData.houseTypeId, houseTypesData)}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F', width: '23%' }}>Address: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000' }}>
                {formData.address || ''}
              </td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>City: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000', width: '25%' }}>
                {getCityName(formData.cityId, citiesData)}
              </td>
            </tr>
          </table>
        </div>
        <div style={{ borderBottom: '#000 1px solid', padding: '5px' }}>
          <h3 style={{ textAlign: 'left', fontWeight: 500, color: '#F33' }}>Sheet Rock Stock:</h3>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tr>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F', width: '23%' }}>Delivery Date: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000' }}>
                {!!formData.deliveryDate ? moment(formData.deliveryDate).format("MM/DD/YYYY") : null}
              </td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>Delivered By: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000', width: '25%' }}>
                {getDeliveredByName(formData.deliveredById, deliveredByData)}
              </td>
            </tr>
          </table>
        </div>
        <div style={{ borderBottom: '#000 1px solid', padding: '5px' }}>
          <h3 style={{ textAlign: 'left', fontWeight: 500, color: '#F33' }}>Schoenberger Drywall:</h3>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tr>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F', width: '23%' }}>Start Date: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000' }}>
                {!!formData.startDate ? moment(formData.startDate).format("MM/DD/YYYY") : null}
              </td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>Sander's End Date: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000', width: '25%' }}>
                {!!formData.closeDate ? moment(formData.closeDate).format("MM/DD/YYYY") : null}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F', width: '23%' }}>Paint Date: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000' }}>
                {!!formData.paintStartDate ? moment(formData.paintStartDate).format("MM/DD/YYYY") : null}
              </td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>Garage Stalls: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000', width: '25%' }}>
                {getGarageStallName(formData.garageStallId, garageStallsData)}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F', width: '23%' }}>Walkthrough Date: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000' }}>
                {!!formData.walkthroughDate ? moment(formData.walkthroughDate).format("MM/DD/YYYY") : null}
              </td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}> &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000', width: '25%' }}></td>
            </tr>
          </table>
        </div>
        <div style={{ borderBottom: '#000 1px solid', padding: '5px' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tr>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F', width: '23%' }}>Ceiling Finish: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000' }}>
                {getCeilingFinishName(formData.ceilingFinishId, ceilingFinishesData)}
              </td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>Garage Finish: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000', width: '25%' }}>
                {getGarageFinishName(formData.garageFinishId, garageFinishesData)}
              </td>
            </tr>
          </table>
        </div>
        <div style={{ borderBottom: '#000 1px solid', padding: '5px' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%', border: '#000 1px solid' }}>
            <tr>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F', border: '#000 1px solid' }}>Electrical Svc Hooked Up:</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000', border: '#000 1px solid' }}>
                <input
                  type="checkbox"
                  name="electric"
                  checked={formData.electric === 1 ? true : false}
                />
              </td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F', border: '#000 1px solid' }}>Heat at Jobsite:</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000', border: '#000 1px solid' }}>
                <input
                  type="checkbox"
                  name="heat"
                  checked={formData.heat === 1 ? true : false}
                />
              </td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F', border: '#000 1px solid' }}>Basement:</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000', border: '#000 1px solid' }}>
                <input
                  type="checkbox"
                  checked={formData.basement === 1 ? true : false}
                />
              </td>
            </tr>
          </table>
        </div>
        <div style={{ borderBottom: '#000 1px solid', padding: '5px' }}>
          <h3 style={{ textAlign: 'left', fontWeight: 500, color: '#F33' }}>Sheet Rock Stocked:</h3>
          <table style={{ borderCollapse: 'collapse', width: '100%', border: '#fff 0px solid' }}>
            <tr>
              <th style={{ border: 'none' }}>&nbsp;</th>
              <th style={{ textAlign: 'center', paddingRight: '5px', color: '#03F', border: 'none' }}>5/8"</th>
              <th style={{ textAlign: 'center', paddingRight: '5px', color: '#03F', border: 'none' }}>HS</th>
              <th style={{ textAlign: 'center', paddingRight: '5px', color: '#03F', border: 'none' }}>1/2"</th>
              <th style={{ textAlign: 'center', paddingRight: '5px', color: '#03F', border: 'none' }}>54"(5/8)</th>
              <th style={{ textAlign: 'center', paddingRight: '5px', color: '#03F', border: 'none' }}>54"(1/2)</th>
            </tr>
            <tr>
              <th style={{ textAlign: 'left', paddingRight: '5px', color: '#03F', border: 'none' }}>UP</th>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.up58 || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.upHs || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.up12 || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.up5458 || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.up5412 || ''}
              </td>
            </tr>
            <tr>
              <th style={{ textAlign: 'left', paddingRight: '5px', color: '#03F', border: 'none' }}>MAIN</th>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.main58 || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.mainHs || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.main12 || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.main5458 || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.main5412 || ''}
              </td>
            </tr>
            <tr>
              <th style={{ textAlign: 'left', paddingRight: '5px', color: '#03F', border: 'none' }}>3rd or Lower</th>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.l358 || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.l3Hs || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.l312 || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.l35458 || ''}
              </td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>
                {formData.l35412 || ''}
              </td>
            </tr>
            {/* <tr>
              <th style={{ textAlign: 'left', paddingRight: '5px', color: '#03F', border: 'none' }}>Garage</th>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>0</td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>14</td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>0</td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>0</td>
              <td style={{ textAlign: 'center', paddingRight: '5px', color: '#000' }}>18</td>
            </tr> */}
          </table>
        </div>
        {/* <div style={{ borderBottom: '#000 1px solid', padding: '5px' }}>
          <h3 style={{ textAlign: 'left', fontWeight: 500, color: '#F33', display: 'inline-block', width: '48%' }}>House:</h3>
          <h3 style={{ textAlign: 'left', fontWeight: 500, color: '#F33', display: 'inline-block', width: '48%' }}>Garage:</h3>
          <div style={{ clear: 'both' }}></div>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tr>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>4x12</td>
              <td style={{ textAlign: 'left', paddingRight: '5px', color: '#000' }}>139</td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>Over 8'</td>
              <td style={{ textAlign: 'left', paddingRight: '5px', color: '#000' }}>0</td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>4x12</td>
              <td style={{ textAlign: 'left', paddingRight: '5px', color: '#000' }}>14</td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>Over 8'</td>
              <td style={{ textAlign: 'left', paddingRight: '5px', color: '#000' }}>0</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>54"</td>
              <td style={{ textAlign: 'left', paddingRight: '5px', color: '#000' }}>132</td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}></td>
              <td style={{ textAlign: 'left', paddingRight: '5px', color: '#000' }}></td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}>54</td>
              <td style={{ textAlign: 'left', paddingRight: '5px', color: '#000' }}>18</td>
              <td style={{ textAlign: 'right', paddingRight: '5px', color: '#03F' }}></td>
              <td style={{ textAlign: 'left', paddingRight: '5px', color: '#000' }}></td>
            </tr>
          </table>
        </div> */}
        <div style={{ padding: '5px' }}>
          <h3 style={{ textAlign: 'left', fontWeight: 500, color: '#F33', display: 'inline-block', width: '48%' }}>Options:</h3>
          {/* <h3 style={{ textAlign: 'left', fontWeight: 500, color: '#F33', display: 'inline-block', width: '48%' }}>Vaults:</h3> */}
          <div style={{ clear: 'both' }}></div>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tr>
              <td style={{ textAlign: 'left', paddingRight: '5px', color: '#03F', width: '25%' }}>Additional Info: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000' }}>
                {formData.additionalInfo || ''}
              </td>
            </tr>
            {/* <tr>
              <td style={{ textAlign: 'left', paddingRight: '5px', color: '#03F', width: '25%' }}>Directions: &nbsp;</td>
              <td style={{ textAlign: 'left', paddingLeft: '5px', color: '#000' }}></td>
            </tr> */}
          </table>
        </div>
      </div>
    </>
  );
};

JioPrint.propTypes = {
  // jobOrders: PropTypes.object.isRequired,
  // actions: PropTypes.func.isRequired
};


const mapStateToProps = (state: JobOrderReduxProps) => ({
  jobOrders: state.jobOrders,
  builders: state.builders,
  users: state.users,
  houseTypes: state.houseTypes,
  cities: state.cities,
  deliveredBy: state.deliveredBy,
  garageStalls: state.garageStalls,
  ceilingFinishes: state.ceilingFinishes,
  garageFinishes: state.garageFinishes,
  vaults: state.vaults,
  options: state.options,
  billingItems: state.billingItems,
  houseLevelTypes: state.houseLevelTypes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    getAllJobOrders: bindActionCreators(JobOrderActions.getAllJobOrders, dispatch),
    getJobOrder: bindActionCreators(JobOrderActions.getJobOrder, dispatch),
    addJobOrder: bindActionCreators(JobOrderActions.addJobOrder, dispatch),
    updateJobOrder: bindActionCreators(JobOrderActions.updateJobOrder, dispatch),
    deleteJobOrder: bindActionCreators(JobOrderActions.deleteJobOrder, dispatch),
    getAllBuilders: bindActionCreators(BuilderActions.getAllBuilders, dispatch),
    getUsers: bindActionCreators(UserActions.getUsers, dispatch),
    getAllHouseTypes: bindActionCreators(HouseTypeActions.getAllHouseTypes, dispatch),
    getAllCities: bindActionCreators(CityActions.getAllCities, dispatch),
    getAllDeliveredBy: bindActionCreators(DeliveredByActions.getAllDeliveredBy, dispatch),
    getAllGarageStalls: bindActionCreators(GarageStallActions.getAllGarageStalls, dispatch),
    getAllCeilingFinishes: bindActionCreators(CeilingFinishActions.getAllCeilingFinishes, dispatch),
    getAllGarageFinishes: bindActionCreators(GarageFinishActions.getAllGarageFinishes, dispatch),
    getAllVaults: bindActionCreators(VaultActions.getAllVaults, dispatch),
    getAllOptions: bindActionCreators(OptionActions.getAllOptions, dispatch),
    getAllBillingItems: bindActionCreators(BillingItemActions.getAllBillingItems, dispatch),
    getAllHouseLevelTypes: bindActionCreators(HouseLevelTypeActions.getAllHouseLevelTypes, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JioPrint);
