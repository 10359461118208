const prod = {
  url: {
    API_URL: 'http://sdi.convextest.com/api',
    API_URL_USERS: 'http://sdi.convextest.com/api/users'
  }
};
const dev = {
  url: {
    API_URL: 'http://127.0.0.1:8000',
    //API_URL: 'https://sdi-online.appexos.com/api',
  }
};
export const configs = process.env.NODE_ENV === 'development' ? dev : prod;
