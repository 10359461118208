import axios from 'axios';
import * as types from './types';
import { returnErrors } from './errorActions';
import { GarageFinish } from '../../types/interfaces';
import History from '../../components/common/History';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';


export const setGarageFinishLoading = () => ({ type: types.GARAGE_FINISHES_LOADING });

export const getAllGarageFinishes = () => (dispatch: Function, getState: Function) => {
  dispatch(setGarageFinishLoading());

  axios
    .get(configs.url.API_URL + '/garage-finishes', authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_ALL_GARAGE_FINISHES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const getGarageFinish = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setGarageFinishLoading());
  axios
    .get(`${configs.url.API_URL}/garage-finish/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_GARAGE_FINISH,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const addGarageFinish = (garageFinish: GarageFinish) => (dispatch: Function, getState: Function) => {
  dispatch(setGarageFinishLoading());
  axios
    .post(configs.url.API_URL + '/garage-finish', garageFinish, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.ADD_GARAGE_FINISH,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const updateGarageFinish = (garageFinish: GarageFinish) => (dispatch: Function, getState: Function) => {
  dispatch(setGarageFinishLoading());
  axios
    .put(configs.url.API_URL + '/garage-finish', garageFinish, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.UPDATE_GARAGE_FINISH,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const deleteGarageFinish = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setGarageFinishLoading());
  axios
    .delete(`${configs.url.API_URL}/garage-finish/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.DELETE_GARAGE_FINISH,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};
