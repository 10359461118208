import axios from 'axios';
import * as types from './types';
import { returnErrors } from './errorActions';
import { IAuthFunction, ExistingUser } from '../../types/interfaces';
import History from '../../components/common/History';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';

export const setUsersLoading = () => ({ type: types.USERS_LOADING });

export const getUsers = () => (dispatch: Function, getState: Function) => {
  dispatch(setUsersLoading());

  axios
    .get(configs.url.API_URL + '/users', authHeader(getState))
    .then((res) => {
      console.log(`getUsers response: ${res.data.data}`);
      dispatch({
        type: types.GET_USERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(`getUsers error: ${err}`);
      console.log(err);
      console.log(err.response.data.msg);
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const addUser = (user: ExistingUser) => (dispatch: Function, getState: Function) => {
  dispatch(setUsersLoading());
  axios
    .post(configs.url.API_URL + '/user', user, authHeader(getState))
    .then((res) => {
      console.log('add user response', res.data.data);
      dispatch({
        type: types.ADD_USER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(`addUser error: ${err}`);
      console.log(err);
      console.log(err.response.data.msg);
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const updateUser = (user: ExistingUser) => (dispatch: Function, getState: Function) => {
  dispatch(setUsersLoading());
  axios
    .put(configs.url.API_URL + '/user', user, authHeader(getState))
    .then((res) => {
      console.log('update user response', res.data.data);
      dispatch({
        type: types.UPDATE_USER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(`updateUser error: ${err}`);
      console.log(err);
      console.log(err.response.data.msg);
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const deleteUser = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setUsersLoading());
  axios
    .delete(`${configs.url.API_URL}/user/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.DELETE_USER,
        payload: id,
      });
    })
    .catch((err) => {
      console.log(`deleteUser error: ${err}`);
      console.log(err);
      console.log(err.response.data.msg);
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};
