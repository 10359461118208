import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from 'react-bootstrap-date-picker';
import moment from 'moment'
import Modal from 'react-modal';
import { useReactToPrint } from 'react-to-print';
import * as JobOrderActions from '../../redux/actions/jobOrderActions';
import * as BuilderActions from '../../redux/actions/builderActions';
import * as HouseTypeActions from '../../redux/actions/houseTypeActions';
import * as UserActions from '../../redux/actions/userActions';
import * as CityActions from '../../redux/actions/cityActions';
import * as DeliveredByActions from '../../redux/actions/deliveredByActions';
import * as GarageStallActions from '../../redux/actions/garageStallActions';
import * as CeilingFinishActions from '../../redux/actions/ceilingFinishActions';
import * as GarageFinishActions from '../../redux/actions/garageFinishActions';
import * as VaultActions from '../../redux/actions/vaultActions';
import * as OptionActions from '../../redux/actions/optionActions';
import * as BillingItemActions from '../../redux/actions/billingItemActions';
import * as HouseLevelTypeActions from '../../redux/actions/houseLevelTypeActions';
import Print from '../common/Print';
import History from '../common/History';

import PropTypes from 'prop-types';
import {
  JobOrderReduxProps,
  JobOrderList,
  JobOrder,
  Target,
} from '../../types/interfaces';
Modal.setAppElement('#root');
const emailModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '500px',
    overlfow: 'scroll',
    width: '50%',
  }
};

const JobOrderPage = ({
  jobOrders,
  builders,
  users,
  houseTypes,
  cities,
  deliveredBy,
  garageStalls,
  ceilingFinishes,
  garageFinishes,
  vaults,
  options,
  billingItems,
  houseLevelTypes,
  actions
}: JobOrderList) => {

  const { id } = useParams();
  const billingItemsLimit = 6;

  useEffect(() => {
    // if(id !== undefined) {
    const jid: number = id !== undefined ? +id : 0;
    //   actions.getJobOrder(jid);
    //   console.log('yes working');
    // }

    actions.getAllJobOrders();
    actions.getAllBuilders();
    actions.getUsers();
    actions.getAllHouseTypes();
    actions.getAllCities();
    actions.getAllDeliveredBy();
    actions.getAllGarageStalls();
    actions.getAllCeilingFinishes();
    actions.getAllGarageFinishes();
    actions.getAllVaults();
    actions.getAllOptions();
    actions.getAllBillingItems();
    actions.getAllHouseLevelTypes();
    actions.getJobOrder(jid);
  }, [
    actions.getAllJobOrders,
    actions.getAllBuilders,
    actions.getUsers,
    actions.getAllHouseTypes,
    actions.getAllCities,
    actions.getAllDeliveredBy,
    actions.getAllGarageStalls,
    actions.getAllCeilingFinishes,
    actions.getAllGarageFinishes,
    actions.getAllVaults,
    actions.getAllOptions,
    actions.getAllBillingItems,
    actions.getAllHouseLevelTypes,
    // actions.getJobOrder,
  ]
  );

  const setFormDataState = () => {
    if (jobOrders.activeJobOrder.id !== undefined) {


      // setFormData({...formData.houseLevels, ...defaultState.houseLevels})
      if (!jobOrders.activeJobOrder.houseLevels.length) {
        console.log('setting value');
        // console.log(defaultState.houseLevels);
        // setFormData({ ...formData.activeJobOrder, houseLevels: [...defaultState.houseLevels] });
        // setFormData({ ...formData, houseLevels: [...defaultState.houseLevels] });
        const x = { ...jobOrders.activeJobOrder };
        x.houseLevels = defaultState.houseLevels;
        setFormData({ ...defaultState, ...x });
      } else {
        const x = { ...jobOrders.activeJobOrder };
        const y = x.houseLevels.map((item: any) => {
          item.billingItems.map((billItem: any) => {
            billItem.billingItemId = parseInt(billItem.billingItemId, 10);
            billItem.columnOrder = parseInt(billItem.columnOrder, 10);
          });

          item.houseLevelTypeId = parseInt(item.houseLevelTypeId, 10);
          return item;
        });
        setFormData({ ...defaultState, ...jobOrders.activeJobOrder });
      }


      const uniqueBillingItemsList: any = [];
      jobOrders.activeJobOrder.houseLevels.map((item: any) => {
        return item.billingItems.map((singleItem: any) => {
          if (!uniqueBillingItemsList.find((uItem: any) => uItem.value == singleItem.billingItemId && uItem.index == singleItem.columnOrder)) {
            const uniqueItem = {
              index: singleItem.columnOrder,
              value: parseInt(singleItem.billingItemId, 10)
            };
            uniqueBillingItemsList.push(uniqueItem);
          }
        })
      });
      setUniqueBillingItems([...uniqueBillingItemsList]);

      const uniqueHouseLevelTypesList: any = [];
      jobOrders.activeJobOrder.houseLevels.map((item: any) => {
        if (!uniqueHouseLevelTypesList.find((hItem: any) => hItem.value == item.houseLevelTypeId && hItem.index == item.rowOrder)) {
          const uniqueItem = {
            index: item.rowOrder,
            value: parseInt(item.houseLevelTypeId, 10)
          };
          uniqueHouseLevelTypesList.push(uniqueItem);
        }
        return item;

        // return item.billingItems.map((singleItem: any) => {
        //   if (!uniqueBillingItemsList.find((uItem: any) => uItem.value == singleItem.billingItemId && uItem.index == singleItem.columnOrder)) {
        //     const uniqueItem = {
        //       index: singleItem.columnOrder,
        //       value: parseInt(singleItem.billingItemId, 10)
        //     };
        //     // console.log('so now what');
        //     // console.log(uniqueItem);
        //     uniqueHouseLevelTypesList.push(uniqueItem);
        //   }
        // })
      });
      setUniqueHouseLevelTypes([...uniqueHouseLevelTypesList]);

    }

  }
  const jid: number = id !== undefined ? +id : 0;
  useEffect(() => {
    setFormDataState();
  }, [
    jobOrders.activeJobOrder
  ]
  );


  // console.log(id);
  const defaultState = {
    id: 0,
    builderId: 0,
    supervisorId: 0,
    name: '',
    houseTypeId: 0,
    address: '',
    cityId: 0,

    deliveryDate: '',
    deliveryTime: '',
    deliveredById: 0,

    startDate: '',
    closeDate: '',
    paintStartDate: '',
    garageStallId: 0,
    walkthroughDate: '',
    ceilingFinishId: 0,
    garageFinishId: 0,
    electric: 0,
    heat: 0,
    basement: 0,


    up58: 0,
    upHs: 0,
    up12: 0,
    up5412: 0,
    up5458: 0,
    main58: 0,
    mainHs: 0,
    main12: 0,
    main5412: 0,
    main5458: 0,
    l358: 0,
    l3Hs: 0,
    l312: 0,
    l35412: 0,
    l35458: 0,
    g58: 0,
    gHs: 0,
    g12: 0,
    g54: 0,
    g5412: 0,
    g5458: 0,
    house4x12: 0,
    house4x12o: 0,
    house54: 0,
    garage4x12: 0,
    garage4x12o: 0,
    garage54: 0,

    houseLevels: [
      {
        houseLevelTypeId: 0,
        rowOrder: 1,
        billingItems: [{
          billingItemId: 0,
          itemValue: '0',
          columnOrder: 1
        }]
      }
    ],

    options: [],
    additionalInfo: '',

    hangerStartDate: '',
    hangerEndDate: '',
    scrapDate: '',
    taperStartDate: '',
    taperEndDate: '',
    sprayerDate: '',
    sanderDate: '',
    paintDate: '',

    status: 1,
  };

  const mailDefaultState = {
    id: 0,
    emailTo: '',
    emailMessage: '',
  };

  const defaultArray: [] | any = [];
  const [formData, setFormData] = useState(defaultState);
  const [uniqueBillingItems, setUniqueBillingItems] = useState(defaultArray);
  const [uniqueHouseLevelTypes, setUniqueHouseLevelTypes] = useState(defaultArray);
  const [submitted, setSubmitted] = useState(false);

  const clearData = () => {
    setSubmitted(false);
    // setTimeout(() => {
    //   History.push('/schedules');
    // }, 1500);
    History.push('/schedules');
    setFormData({ ...defaultState });
  };

  const handleDelete = (e: any, id: number) => {
    e.preventDefault();
    actions.deleteJobOrder(id);
    setTimeout(() => {
      History.push('/schedules');
    }, 1500);
  };

  const handleEdit = (e: any, jobOrder: JobOrder) => {
    e.preventDefault();
    setFormData({ ...defaultState, ...jobOrder });
  };

  const onFormChange = (e: Target) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCheckboxChange = (e: Target) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked ? 1 : 0 });
  };

  const onDateChange = (date: any, name: string) => {
    // console.log(date);
    if (!date) return;
    const fieldObj: any = {};
    const currrentDate = moment(date).format('YYYY-MM-DD');
    if (name == 'deliveryDate') {
      // console.log('current', name);
      // console.log('new change to ', formData.startDate);
      // if (!formData.startDate) {
      // calculate start date
      if (moment(date).day() == 6) {
        fieldObj.startDate = moment(date).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(date).day() == 0) {
        fieldObj.startDate = moment(date).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.startDate = moment(date).format('YYYY-MM-DD');
      }
      // }
      // const startDate = formData.startDate ? formData.startDate : fieldObj.startDate;
      const startDate = fieldObj.startDate;
      fieldObj.startDate = startDate;

      let closeDate = moment(startDate).add('days', 6).format('YYYY-MM-DD');
      // calculate start date
      if (moment(closeDate).day() == 6) {
        fieldObj.closeDate = moment(closeDate).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(closeDate).day() == 0) {
        fieldObj.closeDate = moment(closeDate).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.closeDate = moment(closeDate).format('YYYY-MM-DD');
      }
      // closeDate = formData.closeDate ? formData.closeDate : fieldObj.closeDate;
      closeDate = fieldObj.closeDate;
      fieldObj.closeDate = closeDate;



      let paintStartDate = moment(closeDate).add('days', 1).format('YYYY-MM-DD');
      // calculate start date
      if (moment(paintStartDate).day() == 6) {
        fieldObj.paintStartDate = moment(paintStartDate).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(paintStartDate).day() == 0) {
        fieldObj.paintStartDate = moment(paintStartDate).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.paintStartDate = moment(paintStartDate).format('YYYY-MM-DD');
      }
      // closeDate = formData.closeDate ? formData.closeDate : fieldObj.closeDate;
      paintStartDate = fieldObj.paintStartDate;
      fieldObj.paintStartDate = paintStartDate;


      let walkthroughDate = moment(paintStartDate).add('days', 1).format('YYYY-MM-DD');
      // calculate start date
      if (moment(walkthroughDate).day() == 6) {
        fieldObj.walkthroughDate = moment(walkthroughDate).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(walkthroughDate).day() == 0) {
        fieldObj.walkthroughDate = moment(walkthroughDate).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.walkthroughDate = moment(walkthroughDate).format('YYYY-MM-DD');
      }
      // closeDate = formData.closeDate ? formData.closeDate : fieldObj.closeDate;
      walkthroughDate = fieldObj.walkthroughDate;
      fieldObj.walkthroughDate = walkthroughDate;




      // same as start date
      let hangerStartDate = moment(startDate).add('days', 0).format('YYYY-MM-DD');
      if (moment(hangerStartDate).day() == 6) {
        fieldObj.hangerStartDate = moment(hangerStartDate).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(hangerStartDate).day() == 0) {
        fieldObj.hangerStartDate = moment(hangerStartDate).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.hangerStartDate = moment(hangerStartDate).format('YYYY-MM-DD');
      }
      hangerStartDate = fieldObj.hangerStartDate;
      fieldObj.hangerStartDate = hangerStartDate;



      // one day after start
      let hangerEndDate = moment(hangerStartDate).add('days', 1).format('YYYY-MM-DD');
      if (moment(hangerEndDate).day() == 6) {
        fieldObj.hangerEndDate = moment(hangerEndDate).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(hangerEndDate).day() == 0) {
        fieldObj.hangerEndDate = moment(hangerEndDate).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.hangerEndDate = moment(hangerEndDate).format('YYYY-MM-DD');
      }
      hangerEndDate = fieldObj.hangerEndDate;
      fieldObj.hangerEndDate = hangerEndDate;


      // +1 day hanger end
      let scrapDate = moment(hangerEndDate).add('days', 1).format('YYYY-MM-DD');
      if (moment(scrapDate).day() == 6) {
        fieldObj.scrapDate = moment(scrapDate).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(scrapDate).day() == 0) {
        fieldObj.scrapDate = moment(scrapDate).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.scrapDate = moment(scrapDate).format('YYYY-MM-DD');
      }
      scrapDate = fieldObj.scrapDate;
      fieldObj.scrapDate = scrapDate;


      // same as scrap date
      let taperStartDate = moment(scrapDate).format('YYYY-MM-DD');
      if (moment(taperStartDate).day() == 6) {
        fieldObj.taperStartDate = moment(taperStartDate).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(taperStartDate).day() == 0) {
        fieldObj.taperStartDate = moment(taperStartDate).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.taperStartDate = moment(taperStartDate).format('YYYY-MM-DD');
      }
      taperStartDate = fieldObj.taperStartDate;
      fieldObj.taperStartDate = taperStartDate;


      // +2 days of scrap date
      let taperEndDate = moment(scrapDate).add('days', 2).format('YYYY-MM-DD');
      if (moment(taperEndDate).day() == 6) {
        fieldObj.taperEndDate = moment(taperEndDate).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(taperEndDate).day() == 0) {
        fieldObj.taperEndDate = moment(taperEndDate).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.taperEndDate = moment(taperEndDate).format('YYYY-MM-DD');
      }
      taperEndDate = fieldObj.taperEndDate;
      fieldObj.taperEndDate = taperEndDate;


      // +1 day of taper end date
      let sprayerDate = moment(taperEndDate).add('days', 1).format('YYYY-MM-DD');
      if (moment(sprayerDate).day() == 6) {
        fieldObj.sprayerDate = moment(sprayerDate).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(sprayerDate).day() == 0) {
        fieldObj.sprayerDate = moment(sprayerDate).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.sprayerDate = moment(sprayerDate).format('YYYY-MM-DD');
      }
      sprayerDate = fieldObj.sprayerDate;
      fieldObj.sprayerDate = sprayerDate;


      // +1 day of sprayer date
      let sanderDate = moment(sprayerDate).add('days', 1).format('YYYY-MM-DD');
      if (moment(sanderDate).day() == 6) {
        fieldObj.sanderDate = moment(sanderDate).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(sanderDate).day() == 0) {
        fieldObj.sanderDate = moment(sanderDate).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.sanderDate = moment(sanderDate).format('YYYY-MM-DD');
      }
      sanderDate = fieldObj.sanderDate;
      fieldObj.sanderDate = sanderDate;


      // +1 day of sander date
      let paintDate = moment(sanderDate).add('days', 1).format('YYYY-MM-DD');
      if (moment(paintDate).day() == 6) {
        fieldObj.paintDate = moment(paintDate).add('days', 2).format('YYYY-MM-DD');
      } else if (moment(paintDate).day() == 0) {
        fieldObj.paintDate = moment(paintDate).add('days', 1).format('YYYY-MM-DD');
      } else {
        fieldObj.paintDate = moment(paintDate).format('YYYY-MM-DD');
      }
      paintDate = fieldObj.paintDate;
      fieldObj.paintDate = paintDate;








    }





    // let fields = 'startDate';
    fieldObj[name] = moment(date).format('YYYY-MM-DD');
    setFormData({
      ...formData,
      ...fieldObj
      // [name]: moment(date).format('YYYY-MM-DD'),
      // [fields]: moment(date).format('YYYY-MM-DD')
    });

    // setFormData({ ...formData, [fields]: moment(date).format('YYYY-MM-DD') });
  }

  const onMultiSelectChange = (value: any, name: string) => {
    if (value == null) {
      setFormData({ ...formData, [name]: [] });
    } else {
      setFormData({ ...formData, [name]: [...value] });
    }
  };

  const onStatusChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: parseInt(e.target.value, 10) });
  };


  const onItemSelectChange = (e: any, itemType: string, index: number) => {
    const value: any = parseInt(e.target.value, 10);
    console.log('Testing 1');
    console.log(typeof value);
    if (itemType == 'billing_item') {
      const items = [...uniqueBillingItems];
      if (!value) {
        const b = [...formData.houseLevels];
        const y = b.map(item => {
          const z = item.billingItems
            .filter((billItem) => billItem.columnOrder !== index);
          item.billingItems = z;
          return item;
        });

        setFormData({ ...formData, houseLevels: [...y] });

        const itemsList = items.filter(item => item.index !== index);
        setUniqueBillingItems([...itemsList]);

      } else {


        const b = [...formData.houseLevels];

        console.log('-----');
        console.log(b);
        console.log('-----');
        const y = b.filter(item => {
          return item.billingItems
            .some((billItem: any) => billItem.columnOrder == index)
        });

        console.log('Filter billing items');
        console.log(y);
        if (y.length > 0) {
          const x = b.map(item => {
            item.billingItems
              .map((billItem) => {
                if (billItem.columnOrder == index) {
                  billItem.billingItemId = parseInt(value, 0);
                }
              });
            return item;
          });
        } else {

          const x = b.map(item => {
            const z = {
              billingItemId: parseInt(e.target.value, 0),
              columnOrder: index,
              itemValue: '0'
            }
            item.billingItems.push(z);
            return item;
          });
        }

        setFormData({ ...formData, houseLevels: [...b] });

        const itemsList = items.filter(item => item.index !== index);
        const item = {
          index,
          value
        };
        setUniqueBillingItems([...itemsList, item]);
      }
    } else {
      const items = [...uniqueHouseLevelTypes];
      if (!value) {
        const b = [...formData.houseLevels];

        const x = b.map(item => {
          if (item.rowOrder == index) {
            item.houseLevelTypeId = 0;
          }
          return item;
        });
        setFormData({ ...formData, houseLevels: [...b] });

        const itemsList = items.filter(item => item.index !== index);
        setUniqueHouseLevelTypes([...itemsList]);

      } else {
        const b = [...formData.houseLevels];
        const y = b.filter(item => item.rowOrder == index);

        if (y.length > 0) {
          const x = b.map(item => {
            if (item.rowOrder == index) {
              item.houseLevelTypeId = parseInt(value, 0);
            }
            return item;
          });
        } else {
        }
        setFormData({ ...formData, houseLevels: [...b] });

        const itemsList = items.filter(item => item.index !== index);
        const item = {
          index,
          value
        };
        setUniqueHouseLevelTypes([...itemsList, item]);
      }
    }

  };

  const onBillingItemInputChange = (e: any, rowIndex: number, index: number) => {
    const value = e.target.value;
    const b = [...formData.houseLevels];

    console.log('Row, column and value', rowIndex, index, value);
    console.log(b);

    const y = b.filter(item => {
      return item.rowOrder == rowIndex && item.billingItems
        .some((billItem: any) => billItem.columnOrder == index)
    });
    console.log('After filter');
    console.log(y);


    if (y.length > 0) {
      console.log('length found');
      console.log(y);
      const x = b.map((item, i) => {
        if (i == rowIndex - 1) {
          item.billingItems
            .map((billItem) => {
              if (billItem.columnOrder == index) {
                billItem.itemValue = value;
                console.log('yes updating existing value', billItem);
              }
            });
        }
        return item;
      });
    } else {
      console.log('length not found');
      console.log(y);
      const v = uniqueBillingItems.filter((vv: any) => vv.index == index);
      console.log(v);
      const x = b.map((item, i) => {
        const z = {
          billingItemId: v.length ? parseInt(v[0].value, 10) : 0,
          columnOrder: index,
          itemValue: value
        }
        if (i == rowIndex - 1) {
          console.log('yes pushing new value', z);
          item.billingItems.push(z);
        }
        return item;
      });
    }
    setFormData({ ...formData, houseLevels: [...b] });
  }

  const addNewRow = (e: any) => {
    e.preventDefault();

    const billItems = [];
    billItems.push(
      {
        billingItemId: 0,
        itemValue: '0',
        columnOrder: 1
      }
    );

    let item = {
      houseLevelTypeId: 0,//formData.houseLevels.length + 1,
      rowOrder: formData.houseLevels.length + 1,
      billingItems: billItems
    };

    const houseLevels = [...formData.houseLevels];
    if (houseLevels.length <= 10) {
      setFormData({ ...formData, houseLevels: [...houseLevels, item] });
    }
  };

  const deleteRow = (e: any) => {
    e.preventDefault();
    const houseLevels = [...formData.houseLevels];
    houseLevels.splice(-1, 1);
    setFormData({ ...formData, houseLevels: [...houseLevels] });
  };

  const getBillingItemsOptions = () => {
    const billingItems = billingItemsData.filter(item => !uniqueBillingItems.includes(item.id));
    return billingItems;
  }

  const renderBillingItemsSelectList = () => {
    const items = [];
    for (let i = 1; i <= 6; i++) {
      items.push(<div className={i > 1 ? 'col-md-1' : 'col-md-offset-2 col-md-2'} style={{ width: '13.333333%' }}>{renderBillingItemsSelect(i)}</div>);
    }
    return items;
  };

  const getSelectedBillingItem = (index: number) => {
    const items = formData.houseLevels.filter((item) => {
      return item.billingItems.some((singleItem) => singleItem.columnOrder == index)
    }).map((item) => {
      let singleItem = Object.assign({}, item);
      return singleItem.billingItems.filter(subItem => subItem.columnOrder == index);
    }).flat();

    return items.length > 0 ? items[0].billingItemId : 0;
  }

  const renderBillingItemsSelect = (index: number) => {
    const billingItems = billingItemsData.filter(item => {
      return !uniqueBillingItems.
        some((singleItem: any) => singleItem.value == item.id && singleItem.index !== index
        )
    });

    return (
      <select
        className="form-control"
        name="ddLabel1"
        // defaultValue="0"
        value={getSelectedBillingItem(index)}
        onChange={(e) => onItemSelectChange(e, 'billing_item', index)}
      >

        <option value="0">Select</option>
        {billingItems.length > 0 ? billingItems.map((item: any, index: any) => (
          <option key={index} value={item.id}>{item.billingItemName}</option>
        )) : (<></>)}
      </select>
    );
  }


  const getSelectedHouseLevelType = (index: number) => {
    const item = uniqueHouseLevelTypes.filter((item: any) => item.index == index);
    return item && item.length ? item[0].value.toString() : '0';
  }
  const renderHouseLevelTypesSelect = (index: number, value: any) => {
    const houseLevelTypes = houseLevelTypesData.filter(item => {
      return !uniqueHouseLevelTypes.
        some((singleItem: any) => singleItem.value == item.id && singleItem.index !== index
        )
    });

    return (
      <select
        className="form-control"
        name="ddLabel1"
        value={value || getSelectedHouseLevelType(index)}
        onChange={(e) => onItemSelectChange(e, 'house_level_type', index)}
      >
        <option value="0">Select</option>
        {houseLevelTypes.length > 0 ? houseLevelTypes.map((item: any, index: any) => (
          <option key={index} value={item.id}>{item.houseTypeName}</option>
        )) : (<></>)}
      </select>
    );
  }


  const renderBillingItemsInputList = (rowIndex: number, billingItems: any) => {
    const items = [];
    for (let i = 1; i <= 6; i++) {
      items.push(<>{renderBillingItemInput(rowIndex, i, billingItems)}</>);
    }
    return items;
  };

  const renderBillingItemInput = (rowIndex: number, index: number, billingItems: any) => {

    const value = billingItems.filter((item: any) => {
      return billingItemsData.
        some((singleItem: any) => item.columnOrder == index)
    });
    const itemValue = value.length ? value[0].itemValue : '0';
    return (
      <div key={index} className="col-md-2" style={{ width: '13.333333%' }}>
        <input
          type="text"
          name="billingItemId"
          value={itemValue || 0}
          onChange={(e) => onBillingItemInputChange(e, rowIndex, index)}
          className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
        />
      </div>
    );
  }


  const handleOkay = () => {
    alert('so what');
  };

  const handlePrintSubmit = () => {
    // e.preventDefault();

    setSubmitted(true);
    if (formData.name) {
      if (!formData.id) {
        actions.addJobOrder(formData);
      } else {
        actions.updateJobOrder(formData);
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSubmitted(true);
    if (formData.name) {
      if (!formData.id) {
        actions.addJobOrder(formData);
      } else {
        actions.updateJobOrder(formData);
      }
      setTimeout(() => {
        History.push('/schedules');
      }, 1500);
    }
  };

  const [mailFormData, setMailFormData] = useState(mailDefaultState);
  const [isMailModalOpen, setIsMailModalOpen] = React.useState(false);
  const [mailSubmitted, setMailSubmitted] = useState(false);
  const closeModal = () => {
    setIsMailModalOpen(false);
    setMailSubmitted(false);
  };
  const handleMailModal = () => {
    setIsMailModalOpen(true);
    setMailFormData({ ...mailFormData, id: jid });
  };
  const onMailFormChange = (e: Target) => {
    setMailFormData({ ...mailFormData, [e.target.name]: e.target.value });
  };
  const handleMailSubmit = (e: any) => {
    e.preventDefault();
    setMailSubmitted(true);

    if (mailFormData.emailTo && mailFormData.emailMessage && actions.sendJobOrderEmail !== undefined) {
      actions.sendJobOrderEmail(mailFormData);
    }
  };



  const { jobOrders: jobOrdersData } = jobOrders;
  const { builders: buildersData } = builders;
  const { users: usersData } = users;
  const { houseTypes: houseTypesData } = houseTypes;
  const { cities: citiesData } = cities;
  const { deliveredBy: deliveredByData } = deliveredBy;
  const { garageStalls: garageStallsData } = garageStalls;
  const { ceilingFinishes: ceilingFinishesData } = ceilingFinishes;
  const { garageFinishes: garageFinishesData } = garageFinishes;
  const { vaults: vaultsData } = vaults;
  const { options: optionsData } = options;
  const { billingItems: billingItemsData } = billingItems;
  const { houseLevelTypes: houseLevelTypesData } = houseLevelTypes;

  const componentRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  return (
    <>
      <div className="clear pad-40" />
      <div className="container">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-3">
                  <h3>
                    Schoenberger Drywall Inc.
                    <br />
                    <small>Job Initiation Order</small>
                  </h3>
                </div>
                <div className="col-md-9 mt-20 text-right">
                  {/* <button type="button" className="btn btn-info mr-5">
                    <i className="fas fa-arrow-circle-left mr-5" />
                    Return to Dashboard
                  </button> */}
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handlePrint }
                  >
                    <i className="fas fa-save mr-5" />
                    Save & Print JIO
                  </button> */}

                  <button
                    type="button"
                    className="btn btn-info mr-5"
                    onClick={() => clearData()}
                  >
                    <i className="fas fa-arrow-circle-left mr-5" />
                    Return to Schedules
                  </button>

                  {jid && jid > 0 ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary mr-5"
                        onClick={(e) => handleDelete(e, jid)}
                      >
                        Delete
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary mr-5"
                        onClick={() => handleMailModal()}
                      >
                        Email JIO
                      </button>

                      <Print
                        id={id}
                        label={'Save & Print JIO'}
                        componentName={'jio'}
                        className={"mr-5"}
                        handlePrintSubmit={handlePrintSubmit}
                      />
                      <Print
                        id={id}
                        label={'Print Drywall Final'}
                        className={"mr-5"}
                        componentName={'drywall'}
                      />
                    </>
                  ) : (<></>)}


                  <button form="jio-form" type="submit" className="btn btn-primary">
                    <i className="fas fa-save mr-5" />
                    Save
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body">
              <form id="jio-form" className="form-horizontal" onSubmit={(e) => handleSubmit(e)}>
                <h4 className="text_blue">
                  Builder Details
                </h4>
                <div className="clear pad-5" />
                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Builder :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="builderId"
                        value={formData.builderId || 0}
                        onChange={(e) => onFormChange(e)}
                      >
                        <option value={''}>Select Builder</option>

                        {buildersData.length > 0 ? buildersData.map((singleBuilder) => (
                          <option key={singleBuilder.id} value={singleBuilder.id}>{singleBuilder.name}</option>
                        )) : (<></>)}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Supervisor :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="supervisorId"
                        value={formData.supervisorId || 0}
                        onChange={(e) => onFormChange(e)}
                      >
                        <option value={''}>Select Supervisor</option>

                        {usersData.length > 0 ? usersData.map((singleUser) => (
                          <option key={singleUser.id} value={singleUser.id}>{singleUser.name}</option>
                        )) : (<></>)}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      H/O Name :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        name="name"
                        value={formData.name || ''}
                        onChange={(e) => onFormChange(e)}
                        className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      House Type :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="houseTypeId"
                        value={formData.houseTypeId || 0}
                        onChange={(e) => onFormChange(e)}
                      >
                        <option value={''}>Select House Type</option>

                        {houseTypesData.length > 0 ? houseTypesData.map((singleHouseType) => (
                          <option key={singleHouseType.id} value={singleHouseType.id}>{singleHouseType.name}</option>
                        )) : (<></>)}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Address :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        name="address"
                        value={formData.address || ''}
                        onChange={(e) => onFormChange(e)}
                        className={`form-control ${submitted && !formData.address ? 'ap-required' : ''}`}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      City :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="cityId"
                        value={formData.cityId || 0}
                        onChange={(e) => onFormChange(e)}
                      >
                        <option value={''}>Select City</option>

                        {citiesData.length > 0 ? citiesData.map((singleCity) => (
                          <option key={singleCity.id} value={singleCity.id}>{singleCity.name}</option>
                        )) : (<></>)}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Name :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        name="name"
                        value={formData.name || ''}
                        onChange={(e) => onFormChange(e)}
                        className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                      />
                    </div>
                  </div> */}
                  {/* <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Status :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="status"
                        value={formData.status.toString() || '1'}
                        onChange={(e) => onStatusChange(e)}
                      >
                        <option value={1}>Active</option>
                        <option value={0}>In-Active</option>
                      </select>
                    </div>
                  </div> */}
                </div>







                <hr />
                <h4 className="text_blue">
                  Sheet Rock Stock
                </h4>
                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Delivery Date :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-5">
                      <div className="input-group">
                        <DatePicker
                          name="deliveryDate"
                          selected={!!formData.deliveryDate ? moment(formData.deliveryDate).toDate() : null}
                          onChange={(date) => onDateChange(date, 'deliveryDate')}
                          className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                        />
                        <span className="input-group-addon">
                          <i className="far fa-calendar-alt"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control"
                        name="deliveryTime"
                        value={formData.deliveryTime || ''}
                        onChange={(e) => onFormChange(e)}
                      >
                        <option value="">Please Select</option>
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Delivered By :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="deliveredById"
                        value={formData.deliveredById || 0}
                        onChange={(e) => onFormChange(e)}
                      >
                        <option value={''}>Select Delivered By</option>

                        {deliveredByData.length > 0 ? deliveredByData.map((singleDeliveredBy) => (
                          <option key={singleDeliveredBy.id} value={singleDeliveredBy.id}>{singleDeliveredBy.name}</option>
                        )) : (<></>)}
                      </select>
                    </div>
                  </div>
                </div>






                <hr />
                <h4 className="text_blue">
                  Schoenberger Drywall
                </h4>
                <div className="clear pad-5"></div>
                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Start Date :
                        <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <DatePicker
                          name="startDate"
                          selected={!!formData.startDate ? moment(formData.startDate).toDate() : null}
                          onChange={(date) => onDateChange(date, 'startDate')}
                          className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                        />
                        <span className="input-group-addon">
                          <i className="far fa-calendar-alt"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Sander's End Date:
                        <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <DatePicker
                          name="closeDate"
                          selected={!!formData.closeDate ? moment(formData.closeDate).toDate() : null}
                          onChange={(date) => onDateChange(date, 'closeDate')}
                          className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                        />
                        <span className="input-group-addon">
                          <i className="far fa-calendar-alt"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Paint Date :
                        <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <DatePicker
                          name="paintStartDate"
                          selected={!!formData.paintStartDate ? moment(formData.paintStartDate).toDate() : null}
                          onChange={(date) => onDateChange(date, 'paintStartDate')}
                          className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                        />
                        <span className="input-group-addon">
                          <i className="far fa-calendar-alt"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Garage Stalls :
                          <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="garageStallId"
                        value={formData.garageStallId || 0}
                        onChange={(e) => onFormChange(e)}
                      >
                        <option value={''}>Select Garage Stall</option>

                        {garageStallsData.length > 0 ? garageStallsData.map((singleGarageStall) => (
                          <option key={singleGarageStall.id} value={singleGarageStall.id}>{singleGarageStall.name}</option>
                        )) : (<></>)}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Walkthrough Date :
                      </label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <DatePicker
                          name="walkthroughDate"
                          selected={!!formData.walkthroughDate ? moment(formData.walkthroughDate).toDate() : null}
                          onChange={(date) => onDateChange(date, 'walkthroughDate')}
                          className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                        />
                        <span className="input-group-addon">
                          <i className="far fa-calendar-alt"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="clear"></div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Ceiling Finish :
                      </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="ceilingFinishId"
                        value={formData.ceilingFinishId || 0}
                        onChange={(e) => onFormChange(e)}
                      >
                        <option value={''}>Select Ceiling Finish</option>

                        {ceilingFinishesData.length > 0 ? ceilingFinishesData.map((singleCeilingFinish) => (
                          <option key={singleCeilingFinish.id} value={singleCeilingFinish.id}>{singleCeilingFinish.name}</option>
                        )) : (<></>)}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Garage Finish :
                      </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="garageFinishId"
                        value={formData.garageFinishId || 0}
                        onChange={(e) => onFormChange(e)}
                      >
                        <option value={''}>Select Garage Finish</option>

                        {garageFinishesData.length > 0 ? garageFinishesData.map((singleGarageFinish) => (
                          <option key={singleGarageFinish.id} value={singleGarageFinish.id}>{singleGarageFinish.name}</option>
                        )) : (<></>)}
                      </select>
                    </div>
                  </div>
                  <div className="clear pad-10"></div>
                  <div className="col-md-offset-2 col-md-4 mb-5">
                    <label>
                      <input
                        type="checkbox"
                        name="electric"
                        checked={!!formData.electric}
                        onChange={(e) => onCheckboxChange(e)}
                        className={`${submitted && !formData.electric ? 'ap-required' : ''}`}
                      />
                        Electrical Svc Hooked Up
                      </label>
                  </div>
                  <div className="col-md-3 mb-5">
                    <label>
                      <input
                        type="checkbox"
                        name="heat"
                        checked={!!formData.heat}
                        onChange={(e) => onCheckboxChange(e)}
                        className={`${submitted && !formData.heat ? 'ap-required' : ''}`}
                      />
                        Heat at Jobsite
                      </label>
                  </div>
                  <div className="col-md-3 mb-5">
                    <label>
                      <input
                        type="checkbox"
                        name="basement"
                        checked={!!formData.basement}
                        onChange={(e) => onCheckboxChange(e)}
                        className={`${submitted && !formData.basement ? 'ap-required' : ''}`}
                      />
                        Basement
                      </label>
                  </div>
                </div>






















                <hr />


                <h4 className="text_blue">
                  Sheet Rock Stock House
                </h4>
                <div className="clear pad-5"></div>
                <div className="row">
                  {/* {billingItemsData.length > 0 ? billingItemsData.map((item: any, index: any) => (
                    <div key={index} className={index > 0 ? 'col-md-2 text-center' : 'col-md-offset-2 col-md-2 text-center'}>
                      <h5>{item.billingItemName}</h5>
                    </div>
                  )) : (<></>)} */}

                  {renderBillingItemsSelectList()}
                  {/* <div className="col-md-offset-2 col-md-2">
                    {renderBillingItemsSelect(1)}
                  </div>
                  <div className="col-md-2">
                    {renderBillingItemsSelect(2)}
                  </div>
                  <div className="col-md-2">
                    {renderBillingItemsSelect(3)}
                  </div>
                  <div className="col-md-2">
                    {renderBillingItemsSelect(4)}
                  </div>
                  <div className="col-md-1">
                    {renderBillingItemsSelect(5)}
                  </div>
                  <div className="col-md-1">
                    {renderBillingItemsSelect(6)}
                  </div> */}
                </div>





                <div className="form-group row">

                  {formData.houseLevels.length > 0 ? formData.houseLevels.map((singleLevel: any, i: any) => (
                    <>
                      <div key={i} className="col-md-2">
                        {renderHouseLevelTypesSelect(singleLevel.rowOrder, singleLevel.houseLevelTypeId)}
                      </div>

                      {renderBillingItemsInputList(singleLevel.rowOrder, singleLevel.billingItems)}
                      {/* {singleLevel.billingItems.length > 0 ? formData.houseLevels.map((singleLevel: any, index: any) => (
                        <div key={index} className="col-md-2" style={{ width: '13.333333%' }}>
                          <input
                            type="text"
                            name="billingItemId"
                            value={singleLevel.itemValue || ''}
                            // onChange={(e) => onFormChange(e)}
                            className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                          />
                        </div>
                      )) : (<></>)} */}
                    </>
                  )) : (<>
                    <div key={1} className="col-md-2">
                      {renderHouseLevelTypesSelect(1, 0)}
                    </div>

                    {renderBillingItemsInputList(1, [])}
                  </>)}



                  {/* {billingItemsData.length > 0 ? billingItemsData.map((item: any, i: any) => (
                    <div className="col-md-2">
                      <input
                        type="text"
                        name="billingItemId"
                        value={item.itemValue || ''}
                        // onChange={(e) => onFormChange(e)}
                        className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                      />
                    </div>
                  )) : (<></>)} */}



                </div>



                <div className="pull-right top10">
                  <button type="button" className="btn btn-danger right-10" onClick={(e) => deleteRow(e)}>
                    Delete Last Row
                          </button>
                  <button type="button" className="btn btn-info mr-5" onClick={(e) => addNewRow(e)} disabled={formData.houseLevels.length > 9 ? true : false}>
                    Add New Row
                </button>
                </div>









                <table className="modaltbl">
                  {/* <thead>
                    <tr>
                      {billingItemsData.length > 0 ? billingItemsData.map((item:any, index:any) => (
                        <th>{item.billingItemName}</th>
                      )) : (<></>)}
                    </tr>
                  </thead> */}
                  {/* <tbody>
                    {modalFormData.rpItems.length > 0 ? modalFormData.rpItems.map((dt: any, i: any) => (
                      <tr key={i}>
                        <td>{dt.id}</td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={dt.billitem}
                            name="billitem"
                            style={{ display: activetxt ? 'block' : 'none' }}
                            onChange={(e) => { updateModalFormData(dt, 'billitem', e.target.value) }}
                          />
                          <select
                            className="form-control"
                            name="billitem"
                            value={dt.bill_id}
                            style={{ display: !activetxt ? 'block' : 'none' }}
                            onChange={(e) => updateModalFormData(dt, 'billitem', e.target.value)}
                          >
                            <option value={0}>select...</option>
                            <option value={'new'}>New</option>
                            {dt.jbi_items.length > 0 ? dt.jbi_items.map((jd: any, i: any) => (
                              <option key={i} value={jd?.id}>{jd?.billitem_id}</option>
                            )) : (
                                <option value={0}>select...</option>
                              )}
                          </select>
                        </td>
                        <td><input className="form-control" type="text" value={dt.rate} name="rate" onChange={(e) => { updateModalFormData(dt, 'rate', e.target.value) }} /></td>
                      </tr>
                    )) : (
                        <tr>
                          <td colSpan={4}>Sorry! no data available...</td>
                        </tr>
                      )}
                  </tbody> */}
                </table>
                {/* <div className="pull-right top10">
            <button type="button" className="btn btn-danger right-10" onClick={(e) => deleteRow(e)}>
              Delete Last Row
                    </button>
            <button type="button" className="btn btn-info mr-5" onClick={(e) => addNewRow(e)}>
              Add New Row
          </button>
          </div>
          <div className="pull-left top10">
            <button type="submit" className="btn btn-primary" onClick={(e) => saveModalData(e)}>
              <i className="fas fa-save mr-5" />
              Save
          </button>
          </div> */}






                <hr />
                {/* <div className="form-group row">
                  <label className="col-md-2 text_blue control-label text_20">
                    Garage
                  </label>
                  <div className="col-md-2">
                    <input
                      type="text"
                      name="g58"
                      value={formData.g58 || ''}
                      onChange={(e) => onFormChange(e)}
                      className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                    />
                  </div>
                  <div className="col-md-2">
                    <input
                      type="text"
                      name="gHs"
                      value={formData.gHs || ''}
                      onChange={(e) => onFormChange(e)}
                      className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                    />
                  </div>
                  <div className="col-md-2">
                    <input
                      type="text"
                      name="g12"
                      value={formData.g12 || ''}
                      onChange={(e) => onFormChange(e)}
                      className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                    />
                  </div>
                  <div className="col-md-2">
                    <input
                      type="text"
                      name="g5458"
                      value={formData.g5458 || ''}
                      onChange={(e) => onFormChange(e)}
                      className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                    />
                  </div>
                  <div className="col-md-2">
                    <input
                      type="text"
                      name="g5412"
                      value={formData.g5412 || ''}
                      onChange={(e) => onFormChange(e)}
                      className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`}
                    />
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-md-4 control-label">
                        OPTIONS:
                            <br />
                        <small>
                          Available
                            </small>
                      </label>
                      <div className="col-md-8">
                        <Select
                          isMulti
                          options={optionsData}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.id}
                          value={formData.options}
                          onChange={(value) => onMultiSelectChange(value, 'options')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="clear pad-10"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-md-4 control-label">
                        Additional Info:
                          </label>
                      <div className="col-md-8">
                        <textarea
                          className="form-control"
                          rows={5}
                          name="additionalInfo"
                          value={formData.additionalInfo || ''}
                          onChange={(e) => onFormChange(e)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clear pad-15"></div>










                <div className="row">
                  <div className="col-md-12 mt-20 text-center">
                    <button type="button" className="btn btn-info mr-5" onClick={() => clearData()}>
                      <i className="fas fa-arrow-circle-left mr-5" />
                      Return to Schedules
                    </button>
                    <button type="submit" className="btn btn-primary">
                      <i className="fas fa-save mr-5" />
                      Save
                    </button>
                  </div>
                </div>

                <hr />
                {/* <div className="clear pad-15" /> */}

                {/* <div className="row">
                  <div className="col-md-6 col-md-offset-3">
                    <div className="row">
                      <div className="col-sm-6 mb-5">
                        <button type="button" className="btn btn-info btn-block btn-lg">
                          <i className="fas fa-arrow-circle-left mr-5" />
                          Return to Schedule
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button type="submit" className="btn btn-primary btn-block btn-lg">
                          <i className="fas fa-save mr-5" />
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">

              <h4 className="text_blue">
                JobOrders
              </h4>
              <div className="clear pad-5" />

              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>
                        Name
                      </th>
                      <th>
                        Status
                      </th>
                      <th>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobOrdersData.length > 0 ? jobOrdersData.map((jobOrder) => (
                      <tr key={jobOrder.id}>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          <strong>{jobOrder.name}</strong>
                        </td>
                        <td>
                          {jobOrder.status == 1 ? 'Active' : 'In-Active'}
                        </td>
                        <td>
                          <a
                            href="#"
                            title="Edit"
                            className="text_grey_d"
                            onClick={(e) => handleEdit(e, jobOrder)}
                          >
                            <i className="fa fa-edit fa-lg" />
                          </a>
                          <a
                            href="#"
                            title="Edit"
                            className="text_red"
                            onClick={(e) => handleDelete(e, jobOrder.id)}
                          >
                            <i className="fa fa-times-circle fa-lg" />
                          </a>
                        </td>
                      </tr>
                    )) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No record found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                { jobOrdersData.length < 1 }
                <ul className="pagination center-block">
                  <li><a href="#">«</a></li>
                  <li><a href="#">1</a></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#">4</a></li>
                  <li><a href="#">5</a></li>
                  <li><a href="#">»</a></li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div> */}

      <Modal
        isOpen={isMailModalOpen}
        onRequestClose={closeModal}
        style={emailModalStyles}
        contentLabel="Email JIO"
      >

        <div className="clear pad-15"></div>
        <h3>Email JIO</h3>
        <form className="form-horizontal" onSubmit={(e) => handleMailSubmit(e)}>
          <div className="row">
            <div className="col-md-12">
              <label className="control-label">
                To:
                <span className="text_red">*</span>
              </label>
              <input
                type="text"
                name="emailTo"
                onChange={(e) => onMailFormChange(e)}
                className={`form-control ${mailSubmitted && !mailFormData.emailTo ? 'ap-required' : ''}`}
              />
            </div>
            <div className="clear pad-15"></div>
            <div className="col-md-12">
              <label className="control-label">
                Message:
              </label>
              <textarea
                rows={5}
                name="emailMessage"
                onChange={(e) => onMailFormChange(e)}
                className={`form-control ${mailSubmitted && !mailFormData.emailMessage ? 'ap-required' : ''}`}
              ></textarea>
            </div>
          </div>
          <div className="clear pad-15"></div>
          <div className="pull-right">
            <button className="btn btn-default mr-5"
              onClick={closeModal}
            >
              Close
            </button>
            <button type="submit" className="btn btn-info">
              Send
            </button>
          </div>
        </form>
      </Modal>

    </>
  );
};

JobOrderPage.propTypes = {
  // jobOrders: PropTypes.object.isRequired,
  // actions: PropTypes.func.isRequired
};


const mapStateToProps = (state: JobOrderReduxProps) => ({
  jobOrders: state.jobOrders,
  builders: state.builders,
  users: state.users,
  houseTypes: state.houseTypes,
  cities: state.cities,
  deliveredBy: state.deliveredBy,
  garageStalls: state.garageStalls,
  ceilingFinishes: state.ceilingFinishes,
  garageFinishes: state.garageFinishes,
  vaults: state.vaults,
  options: state.options,
  billingItems: state.billingItems,
  houseLevelTypes: state.houseLevelTypes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    getAllJobOrders: bindActionCreators(JobOrderActions.getAllJobOrders, dispatch),
    getJobOrder: bindActionCreators(JobOrderActions.getJobOrder, dispatch),
    addJobOrder: bindActionCreators(JobOrderActions.addJobOrder, dispatch),
    updateJobOrder: bindActionCreators(JobOrderActions.updateJobOrder, dispatch),
    deleteJobOrder: bindActionCreators(JobOrderActions.deleteJobOrder, dispatch),
    sendJobOrderEmail: bindActionCreators(JobOrderActions.sendJobOrderEmail, dispatch),
    getAllBuilders: bindActionCreators(BuilderActions.getAllBuilders, dispatch),
    getUsers: bindActionCreators(UserActions.getUsers, dispatch),
    getAllHouseTypes: bindActionCreators(HouseTypeActions.getAllHouseTypes, dispatch),
    getAllCities: bindActionCreators(CityActions.getAllCities, dispatch),
    getAllDeliveredBy: bindActionCreators(DeliveredByActions.getAllDeliveredBy, dispatch),
    getAllGarageStalls: bindActionCreators(GarageStallActions.getAllGarageStalls, dispatch),
    getAllCeilingFinishes: bindActionCreators(CeilingFinishActions.getAllCeilingFinishes, dispatch),
    getAllGarageFinishes: bindActionCreators(GarageFinishActions.getAllGarageFinishes, dispatch),
    getAllVaults: bindActionCreators(VaultActions.getAllVaults, dispatch),
    getAllOptions: bindActionCreators(OptionActions.getAllOptions, dispatch),
    getAllBillingItems: bindActionCreators(BillingItemActions.getAllBillingItems, dispatch),
    getAllHouseLevelTypes: bindActionCreators(HouseLevelTypeActions.getAllHouseLevelTypes, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobOrderPage);
