import * as types from '../actions/types';
import { Action, Option } from '../../types/interfaces';


const initialState = {
  options: [],
  loading: false,
};

interface State {
  options: Option[];
}


export default function (state: State = initialState, action: Action) {
  switch (action.type) {
    case types.OPTIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_OPTIONS:
      return {
        ...state,
        options: action.payload,
        loading: false,
      };
    case types.ADD_OPTION:
      return {
        ...state,
        options: [action.payload, ...state.options],
      };
    case types.UPDATE_OPTION:
      return {
        ...state,
        options: state.options.map((option) => {
          return option.id !== action.payload.id ? option : {
            ...option,
            name: action.payload.name,
            status: action.payload.status,
          };
        }),
      };
    case types.DELETE_OPTION:
      return {
        ...state,
        options: state.options.filter((option) => option.id !== action.payload),
      };
    default:
      return {
        ...state,
      };
  }
}
