import axios from 'axios';
import * as types from './types';
import { returnErrors } from './errorActions';
import { HouseLevelType } from '../../types/interfaces';
import History from '../../components/common/History';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';


export const setHouseLevelTypesLoading = () => ({ type: types.HOUSE_LEVEL_TYPES_LOADING });

export const getAllHouseLevelTypes = () => (dispatch: Function, getState: Function) => {
  dispatch(setHouseLevelTypesLoading());

  axios
    .get(configs.url.API_URL + '/house-level-types', authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_ALL_HOUSE_LEVEL_TYPES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const getHouseLevelType = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setHouseLevelTypesLoading());
  axios
    .get(`${configs.url.API_URL}/house-level-type/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_HOUSE_LEVEL_TYPE,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const addHouseLevelType = (houseLevelType: HouseLevelType) => (dispatch: Function, getState: Function) => {
  dispatch(setHouseLevelTypesLoading());
  axios
    .post(configs.url.API_URL + '/house-level-type', houseLevelType, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.ADD_HOUSE_LEVEL_TYPE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const updateHouseLevelType = (houseLevelType: HouseLevelType) => (dispatch: Function, getState: Function) => {
  dispatch(setHouseLevelTypesLoading());
  axios
    .put(configs.url.API_URL + '/house-level-type', houseLevelType, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.UPDATE_HOUSE_LEVEL_TYPE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const deleteHouseLevelType = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setHouseLevelTypesLoading());
  axios
    .delete(`${configs.url.API_URL}/house-level-type/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.DELETE_HOUSE_LEVEL_TYPE,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};
