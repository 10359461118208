import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from 'react-bootstrap-date-picker';
import moment from 'moment'
import * as JobOrderActions from '../../redux/actions/jobOrderActions';
import * as BuilderActions from '../../redux/actions/builderActions';
import * as HouseTypeActions from '../../redux/actions/houseTypeActions';
import * as UserActions from '../../redux/actions/userActions';
import * as CityActions from '../../redux/actions/cityActions';
import * as DeliveredByActions from '../../redux/actions/deliveredByActions';
import * as GarageStallActions from '../../redux/actions/garageStallActions';
import * as CeilingFinishActions from '../../redux/actions/ceilingFinishActions';
import * as GarageFinishActions from '../../redux/actions/garageFinishActions';
import * as VaultActions from '../../redux/actions/vaultActions';
import * as OptionActions from '../../redux/actions/optionActions';
import * as BillingItemActions from '../../redux/actions/billingItemActions';
import * as HouseLevelTypeActions from '../../redux/actions/houseLevelTypeActions';

import PropTypes from 'prop-types';
import {
  JobOrderReduxProps,
  JobOrderList,
  JobOrder,
  Target,
} from '../../types/interfaces';


const SchedulePage = ({
  jobOrders,
  builders,
  users,
  houseTypes,
  cities,
  deliveredBy,
  garageStalls,
  ceilingFinishes,
  garageFinishes,
  vaults,
  options,
  billingItems,
  houseLevelTypes,
  actions
}: JobOrderList) => {

  const { id } = useParams();

  useEffect(() => {
    // if(id !== undefined) {
    const jid: number = id !== undefined ? +id : 0;
    //   actions.getJobOrder(jid);
    //   console.log('yes working');
    // }


    actions.getAllBuilders();
    actions.getUsers();
    actions.getAllHouseTypes();
    actions.getAllCities();
    actions.getAllDeliveredBy();
    actions.getAllGarageStalls();
    actions.getAllCeilingFinishes();
    actions.getAllGarageFinishes();
    actions.getAllVaults();
    actions.getAllOptions();
    actions.getJobOrder(jid);
    actions.getAllJobOrders();
  }, [

    actions.getAllBuilders,
    actions.getUsers,
    actions.getAllHouseTypes,
    actions.getAllCities,
    actions.getAllDeliveredBy,
    actions.getAllGarageStalls,
    actions.getAllCeilingFinishes,
    actions.getAllGarageFinishes,
    actions.getAllVaults,
    actions.getAllOptions,
    actions.getAllJobOrders,
    // actions.getJobOrder,
  ]
  );

  // // interface ParamTypes {
  // //   id: string
  // // }
  // let { id } = useParams();
  // console.log(id);
  // // var jid: number = +id;
  // // console.log(this.props.params.id);

  const setFormDataState = () => {
    if (jobOrders.activeJobOrder.id !== undefined) {
      setFormData({ ...defaultState, ...jobOrders.activeJobOrder });
    }

  }
  const jid: number = id !== undefined ? +id : 0;
  useEffect(() => {
    // console.log(typeof id);
    // const jid = parseInt(id);
    // const jid: number = parseInt(id, 10);
    // if(id !== undefined) {
    //   const jid: number = +id;
    // actions.getJobOrder(jid);
    setFormDataState();
    //   console.log('yes working');
    // }
  }, [
    jobOrders.activeJobOrder
    // actions.getJobOrder
  ]
  );

  // console.log(id);
  const defaultState = {
    id: 0,
    builderId: 0,
    supervisorId: 0,
    name: '',
    houseTypeId: 0,
    address: '',
    cityId: 0,

    deliveryDate: '',
    deliveryTime: '',
    deliveredById: 0,

    startDate: '',
    closeDate: '',
    paintStartDate: '',
    garageStallId: 0,
    walkthroughDate: '',
    ceilingFinishId: 0,
    garageFinishId: 0,
    electric: 0,
    heat: 0,
    basement: 0,


    up58: 0,
    upHs: 0,
    up12: 0,
    up5412: 0,
    up5458: 0,
    main58: 0,
    mainHs: 0,
    main12: 0,
    main5412: 0,
    main5458: 0,
    l358: 0,
    l3Hs: 0,
    l312: 0,
    l35412: 0,
    l35458: 0,
    g58: 0,
    gHs: 0,
    g12: 0,
    g54: 0,
    g5412: 0,
    g5458: 0,
    house4x12: 0,
    house4x12o: 0,
    house54: 0,
    garage4x12: 0,
    garage4x12o: 0,
    garage54: 0,

    houseLevels: [],

    options: [],
    additionalInfo: '',

    status: 1,

    hangerStartDate: '',
    hangerEndDate: '', // one day after start
    scrapDate: '', // +1 day hanger end
    taperStartDate: '', // same as scrap date
    taperEndDate: '', // +2 days of scrap date
    sprayerDate: '', // +1 day of taper end date
    sanderDate: '', // +1 day of sprayer date
    paintDate: '', // +1 day of sander date
  };

  const [formData, setFormData] = useState(defaultState);
  const [submitted, setSubmitted] = useState(false);

  const clearData = () => {
    setSubmitted(false);
    setFormData({ ...defaultState });
  };

  const handleDelete = (e: any, id: number) => {
    e.preventDefault();
    actions.deleteJobOrder(id);
  };

  const handleEdit = (e: any, jobOrder: JobOrder) => {
    e.preventDefault();
    setFormData({ ...defaultState, ...jobOrder });
  };

  const onFormChange = (e: Target) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCheckboxChange = (e: Target) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked ? 1 : 0 });
  };

  const onDateChange = (date: any, name: string) => {
    // setFormData({ ...formData, [name]: moment(date).format('YYYY-MM-DD') });
  }

  const onMultiSelectChange = (value: any, name: string) => {
    if (value === null) {
      setFormData({ ...formData, [name]: [] });
    } else {
      setFormData({ ...formData, [name]: [...value] });
    }
  };

  const onStatusChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: parseInt(e.target.value, 10) });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSubmitted(true);
    if (formData.name) {
      if (!formData.id) {
        actions.addJobOrder(formData);
      } else {
        actions.updateJobOrder(formData);
      }
    }
  };

  const calculateDate = (date: any, dayInterval: number) => {
    const currrentDate = moment(date).format('YYYY-MM-DD');
    // console.log(date);

    if (!date) {
      return null;
    }
    // return moment(date).format('YYYY-MM-DD');
    // date = moment(date).toDate();
    dayInterval = dayInterval ? dayInterval : 0;
    let targetDate = moment(date).add('days', dayInterval).format('YYYY-MM-DD');
    let newDate = '';
    // calculate start date
    if (moment(targetDate).day() === 6) {
      newDate = moment(targetDate).add('days', 2).format('YYYY-MM-DD');
    } else if (moment(targetDate).day() === 0) {
      newDate = moment(targetDate).add('days', 1).format('YYYY-MM-DD');
    } else {
      newDate = moment(targetDate).format('YYYY-MM-DD');
    }
    return newDate;
  }

  const highlightBackgroundClass = (date: any, dayInterval: number) => {
    const currentDate = moment().add('days', 1);
    // console.log(date);

    if (!date) {
      return '';
    }
    // return moment(date).format('YYYY-MM-DD');
    date = moment(date).toDate();
    dayInterval = dayInterval ? dayInterval : 0;
    let targetDate = moment(date).add('days', dayInterval);

    let highlightClass = '';
    if (currentDate.isSame(targetDate, 'd')) {
      highlightClass = 'light-green';
    }
    return highlightClass;
  }

  const getfilteredUsers = (usersData: any, userTypeId: number) => {
    const newData = usersData.filter((user: any) => {
      // console.log(user.userTypes);
      return user.userTypes.some((userType: any) => parseInt(userType.id, 10) === userTypeId)
      // return user.userTypes.some((userType: any) => userType.id === userTypeId)

    });
    // console.log(newData);
    return newData;
  }

  const { jobOrders: jobOrdersData } = jobOrders;
  const { builders: buildersData } = builders;
  const { users: usersData } = users;
  const { houseTypes: houseTypesData } = houseTypes;
  const { cities: citiesData } = cities;
  const { deliveredBy: deliveredByData } = deliveredBy;
  const { garageStalls: garageStallsData } = garageStalls;
  const { ceilingFinishes: ceilingFinishesData } = ceilingFinishes;
  const { garageFinishes: garageFinishesData } = garageFinishes;
  const { vaults: vaultsData } = vaults;
  const { options: optionsData } = options;
  const { billingItems: billingItemsData } = billingItems;
  const { houseLevelTypes: houseLevelTypesData } = houseLevelTypes;

  const getJobOrderCeilingFinish = (ceilingFinishId: any) => {
    const ceilingFinish = ceilingFinishesData.filter((cf) => cf.id === parseInt(ceilingFinishId, 10));
    return (
      <>
        {ceilingFinish.length > 0 ? ceilingFinish[0].name : ''}
      </>
    );
  };

  return (
    <>
      <div className="clear pad-40" />
      <div className="">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">

              <h4 className="text_blue">
                Overall Schedule
              </h4>
              <span>{moment().format('MM/DD/YYYY')}</span>

              <div className="filter-container">
                <form className="form-horizontal">
                  <div className="rows">
                    <div className="form-group col-md-3 mb-20 text-center">
                      <label className="control-label ">
                        Address
                      </label>
                      <div className="mr-5">
                        <input
                          type="text"
                          name="address"
                          value={formData.name || ''}
                          className={`form-control`}
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-3 mb-20 text-center">
                      <label className="control-label">
                        Hanger
                      </label>
                      <div className="mr-5">
                        <select
                          className="form-control"
                          name="hanger"
                        // onChange={(e) => onFormChange(e)}
                        >
                          <option value={''}>Hanger</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group col-md-3 mb-20 text-center">
                      <label className="control-label">
                        Taper
                      </label>
                      <div className="mr-5">
                        <select
                          className="form-control"
                          name="taper"
                        // onChange={(e) => onFormChange(e)}
                        >
                          <option value={''}>Taper</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group col-md-3 mb-20 text-center">
                      <label className="control-label">
                        Sprayer
                      </label>
                      <div className="mr-5">
                        <select
                          className="form-control"
                          name="sprayer"
                        // onChange={(e) => onFormChange(e)}
                        >
                          <option value={''}>Sprayer</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group col-md-3 mb-20 text-center">
                      <label className="control-label">
                        Sander
                      </label>
                      <div className="mr-5">
                        <select
                          className="form-control"
                          name="sander"
                        // onChange={(e) => onFormChange(e)}
                        >
                          <option value={''}>Hanger</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group col-md-3 mb-20">
                      <div className="">
                        <label className="">
                          Closed Between
                        </label>
                        <div className="mr-5">
                          <DatePicker
                            name="start"
                            selected={!!formData.deliveryDate ? moment(formData.deliveryDate).toDate() : null}
                            onChange={(date) => onDateChange(date, 'start')}
                            className={`form-control`}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-3 mb-20">
                      <div className="">
                        <label className="">
                          And
                        </label>
                        <div className="mr-5">
                          <DatePicker
                            name="end"
                            selected={!!formData.deliveryDate ? moment(formData.deliveryDate).toDate() : null}
                            onChange={(date) => onDateChange(date, 'end')}
                            className={`form-control`}
                          />
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group col-md-12 mb-20">
                        <button className="btn btn-default mr-10">
                          Done
                          </button>
                        <button className="btn btn-default mr-10">
                          Hold
                          </button>
                        <button className="btn btn-default mr-10">
                          UnHold
                          </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="clear pad-5" />

              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      {/* <th>
                        <input type="checkbox" />
                      </th> */}
                      <th className="w-100">
                        Action
                      </th>
                      <th className="w-150">
                        Address
                      </th>
                      <th className="w-150">
                        Ceil Fin
                      </th>
                      <th>
                        Ver
                      </th>
                      <th>
                        12'
                      </th>
                      <th>
                        54"
                      </th>
                      <th>
                        High
                      </th>
                      <th>
                        Gar
                      </th>
                      <th>
                        Gar H
                      </th>
                      <th>
                        Hanger
                      </th>
                      <th>

                      </th>
                      <th className="w-140">
                        Start mm/dd/yy
                      </th>
                      <th className="w-140">
                        End mm/dd/yy
                      </th>
                      <th className="w-140">
                        Scrap mm/dd/yy
                      </th>
                      <th>
                        Taper
                      </th>
                      <th>

                      </th>
                      <th className="w-140">
                        Start mm/dd/yy
                      </th>
                      <th className="w-140">
                        End mm/dd/yyy
                      </th>
                      <th className="w-140">
                        Fog Date mm/dd/yy
                      </th>
                      <th className="w-140">
                        KD Date mm/dd/yy
                      </th>
                      <th>
                        Sprayer
                      </th>
                      <th>

                      </th>
                      <th className="w-140">
                        Date mm/dd/yy
                      </th>
                      <th>
                        Sander
                      </th>
                      <th>

                      </th>
                      <th className="w-140">
                        Date mm/dd/yy
                      </th>
                      <th className="w-140">
                        Scrape mm/dd/yy
                      </th>
                      <th className="w-140">
                        Paint mm/dd/yy
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobOrdersData.length > 0 ? jobOrdersData.map((jobOrder, i) => (
                      <tr key={jobOrder.id}>
                        {/* <td>
                          {i + 1}
                        </td> */}
                        <td>
                          <a href={`/job-orders/${jobOrder.id}`} className="">
                            Edit
                          </a>
                          <input type="button" value="Ver" />
                          <input type="checkbox" />
                        </td>
                        <td>
                          <strong>{jobOrder.address}</strong>
                        </td>
                        <td>
                          {getJobOrderCeilingFinish(jobOrder.ceilingFinishId)}
                        </td>
                        <td>
                          <i className="fa fa-check fa-lg" />
                        </td>
                        <td>
                          182s
                        </td>
                        <td>
                          58s
                        </td>
                        <td>
                          0s
                        </td>
                        <td>
                          32s
                        </td>
                        <td>
                          0s
                        </td>
                        <td>
                          <select>
                            {getfilteredUsers(usersData, 4).length > 0 ? getfilteredUsers(usersData, 4).map((singleUser: any) => (
                              <option key={singleUser.id} value={singleUser.id}>{singleUser.name}</option>
                            )) : (<></>)}
                          </select>




                        </td>
                        <td>
                          <input type="button" value="Sub" />
                        </td>
                        <td>
                          <div className="input-group">
                            <DatePicker
                              name="start1"
                              selected={!!jobOrder.hangerStartDate ? moment(calculateDate(jobOrder.hangerStartDate, 0)).toDate() : null}
                              onChange={(date) => onDateChange(date, 'deliveryDate')}
                              className={`form-control h-30 ${highlightBackgroundClass(jobOrder.hangerStartDate, 0)}`}
                            />
                            <span className="input-group-addon">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="input-group">
                            <DatePicker
                              name="end1"
                              selected={!!jobOrder.hangerEndDate ? moment(calculateDate(jobOrder.hangerEndDate, 0)).toDate() : null}
                              onChange={(date) => onDateChange(date, 'deliveryDate')}
                              className={`form-control h-30 ${highlightBackgroundClass(jobOrder.hangerEndDate, 0)}`}
                            />
                            <span className="input-group-addon">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="input-group">
                            <DatePicker
                              name="scrap1"
                              selected={!!jobOrder.scrapDate ? moment(calculateDate(jobOrder.scrapDate, 0)).toDate() : null}
                              onChange={(date) => onDateChange(date, 'deliveryDate')}
                              className={`form-control h-30 ${highlightBackgroundClass(jobOrder.scrapDate, 0)}`}
                            />
                            <span className="input-group-addon">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <select>
                            {getfilteredUsers(usersData, 7).length > 0 ? getfilteredUsers(usersData, 7).map((singleUser: any) => (
                              <option key={singleUser.id} value={singleUser.id}>{singleUser.name}</option>
                            )) : (<></>)}
                          </select>
                        </td>
                        <td>
                          <input type="button" value="Sub" />
                        </td>
                        <td>
                          <div className="input-group">
                            <DatePicker
                              name="start2"
                              selected={!!jobOrder.taperStartDate ? moment(calculateDate(jobOrder.taperStartDate, 0)).toDate() : null}
                              onChange={(date) => onDateChange(date, 'deliveryDate')}
                              className={`form-control h-30 ${highlightBackgroundClass(jobOrder.taperStartDate, 0)}`}
                            />
                            <span className="input-group-addon">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="input-group">
                            <DatePicker
                              name="end2"
                              selected={!!jobOrder.taperEndDate ? moment(calculateDate(jobOrder.taperEndDate, 0)).toDate() : null}
                              onChange={(date) => onDateChange(date, 'deliveryDate')}
                              className={`form-control h-30 ${highlightBackgroundClass(jobOrder.taperEndDate, 0)}`}
                            />
                            <span className="input-group-addon">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="input-group">
                            <DatePicker
                              name="fog2"
                              selected={!!formData.deliveryDate ? null : null}
                              onChange={(date) => onDateChange(date, 'deliveryDate')}
                              className={`form-control h-30`}
                            />
                            <span className="input-group-addon">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="input-group">
                            <DatePicker
                              name="kdstart"
                              selected={!!formData.deliveryDate ? null : null}
                              onChange={(date) => onDateChange(date, 'deliveryDate')}
                              className={`form-control h-30`}
                            />
                            <span className="input-group-addon">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <select>
                            {getfilteredUsers(usersData, 5).length > 0 ? getfilteredUsers(usersData, 5).map((singleUser: any) => (
                              <option key={singleUser.id} value={singleUser.id}>{singleUser.name}</option>
                            )) : (<></>)}
                          </select>
                        </td>
                        <td>
                          <input type="button" value="sub" />
                        </td>
                        <td>
                          <div className="input-group">
                            <DatePicker
                              name="kdstart"
                              selected={!!jobOrder.sprayerDate ? moment(calculateDate(jobOrder.sprayerDate, 0)).toDate() : null}
                              onChange={(date) => onDateChange(date, 'deliveryDate')}
                              className={`form-control h-30 ${highlightBackgroundClass(jobOrder.sprayerDate, 0)}`}
                            />
                            <span className="input-group-addon">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <select>
                            <option>Capital Dr</option>
                          </select>
                        </td>
                        <td>
                          <input type="button" value="sub" />
                        </td>
                        <td>
                          <div className="input-group">
                            <DatePicker
                              name="kdstart"
                              selected={!!jobOrder.sanderDate ? moment(calculateDate(jobOrder.sanderDate, 0)).toDate() : null}
                              onChange={(date) => onDateChange(date, 'deliveryDate')}
                              className={`form-control h-30 ${highlightBackgroundClass(jobOrder.sanderDate, 0)}`}
                            />
                            <span className="input-group-addon">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="input-group">
                            <DatePicker
                              name="kdstart"
                              selected={!!formData.deliveryDate ? null : null}
                              onChange={(date) => onDateChange(date, 'deliveryDate')}
                              className={`form-control h-30`}
                            />
                            <span className="input-group-addon">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="input-group">
                            <DatePicker
                              name="kdstart"
                              selected={!!jobOrder.paintDate ? moment(calculateDate(jobOrder.paintDate, 0)).toDate() : null}
                              onChange={(date) => onDateChange(date, 'deliveryDate')}
                              className={`form-control h-30 ${highlightBackgroundClass(jobOrder.paintDate, 0)}`}
                            />
                            <span className="input-group-addon">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                        </td>
                      </tr>
                    )) : (
                        <tr>
                          <td colSpan={29} className="text-center">
                            No record found.
                        </td>
                        </tr>
                      )}
                  </tbody>
                </table>
                {jobOrdersData.length < 1}
                {/* <ul className="pagination center-block">
                  <li><a href="#">«</a></li>
                  <li><a href="#">1</a></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#">4</a></li>
                  <li><a href="#">5</a></li>
                  <li><a href="#">»</a></li>
                </ul> */}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SchedulePage.propTypes = {
  // jobOrders: PropTypes.object.isRequired,
  // actions: PropTypes.func.isRequired
};


const mapStateToProps = (state: JobOrderReduxProps) => ({
  jobOrders: state.jobOrders,
  builders: state.builders,
  users: state.users,
  houseTypes: state.houseTypes,
  cities: state.cities,
  deliveredBy: state.deliveredBy,
  garageStalls: state.garageStalls,
  ceilingFinishes: state.ceilingFinishes,
  garageFinishes: state.garageFinishes,
  vaults: state.vaults,
  options: state.options,
  billingItems: state.billingItems,
  houseLevelTypes: state.houseLevelTypes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    getAllJobOrders: bindActionCreators(JobOrderActions.getAllJobOrders, dispatch),
    getJobOrder: bindActionCreators(JobOrderActions.getJobOrder, dispatch),
    addJobOrder: bindActionCreators(JobOrderActions.addJobOrder, dispatch),
    updateJobOrder: bindActionCreators(JobOrderActions.updateJobOrder, dispatch),
    deleteJobOrder: bindActionCreators(JobOrderActions.deleteJobOrder, dispatch),
    getAllBuilders: bindActionCreators(BuilderActions.getAllBuilders, dispatch),
    getUsers: bindActionCreators(UserActions.getUsers, dispatch),
    getAllHouseTypes: bindActionCreators(HouseTypeActions.getAllHouseTypes, dispatch),
    getAllCities: bindActionCreators(CityActions.getAllCities, dispatch),
    getAllDeliveredBy: bindActionCreators(DeliveredByActions.getAllDeliveredBy, dispatch),
    getAllGarageStalls: bindActionCreators(GarageStallActions.getAllGarageStalls, dispatch),
    getAllCeilingFinishes: bindActionCreators(CeilingFinishActions.getAllCeilingFinishes, dispatch),
    getAllGarageFinishes: bindActionCreators(GarageFinishActions.getAllGarageFinishes, dispatch),
    getAllVaults: bindActionCreators(VaultActions.getAllVaults, dispatch),
    getAllOptions: bindActionCreators(OptionActions.getAllOptions, dispatch),
    getAllBillingItems: bindActionCreators(BillingItemActions.getAllBillingItems, dispatch),
    getAllHouseLevelTypes: bindActionCreators(HouseLevelTypeActions.getAllHouseLevelTypes, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SchedulePage);
