import * as types from '../actions/types';
import { Action, GarageStall } from '../../types/interfaces';


const initialState = {
  garageStalls: [],
  loading: false,
};

interface State {
  garageStalls: GarageStall[];
}


export default function (state: State = initialState, action: Action) {
  switch (action.type) {
    case types.GARAGE_STALLS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_GARAGE_STALLS:
      return {
        ...state,
        garageStalls: action.payload,
        loading: false,
      };
    case types.ADD_GARAGE_STALL:
      return {
        ...state,
        garageStalls: [action.payload, ...state.garageStalls],
      };
    case types.UPDATE_GARAGE_STALL:
      return {
        ...state,
        garageStalls: state.garageStalls.map((garageStall) => {
          return garageStall.id !== action.payload.id ? garageStall : {
            ...garageStall,
            name: action.payload.name,
            status: action.payload.status,
          };
        }),
      };
    case types.DELETE_GARAGE_STALL:
      return {
        ...state,
        garageStalls: state.garageStalls.filter((garageStall) => garageStall.id !== action.payload),
      };
    default:
      return {
        ...state,
      };
  }
}
