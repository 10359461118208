import * as types from '../actions/types';
import { Action, JobOrder } from '../../types/interfaces';


const initialState = {
  jobOrders: [],
  activeJobOrder: {},
  loading: false,
};

interface State {
  jobOrders: JobOrder[];
}


export default function (state: State = initialState, action: Action) {
  switch (action.type) {
    case types.JOB_ORDERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_JOB_ORDERS:
      return {
        ...state,
        jobOrders: action.payload,
        loading: false,
      };
    case types.GET_JOB_ORDER:
      return {
        ...state,
        activeJobOrder: action.payload,
        loading: false,
      };
    case types.ADD_JOB_ORDER:
      return {
        ...state,
        jobOrders: [action.payload, ...state.jobOrders],
      };
    case types.UPDATE_JOB_ORDER:
      return {
        ...state,
        jobOrders: state.jobOrders.map((jobOrder) => {
          return jobOrder.id !== action.payload.id ? jobOrder : {
            ...jobOrder,
            name: action.payload.name,
            status: action.payload.status,
          };
        }),
        activeJobOrder: action.payload,
      };
    case types.DELETE_JOB_ORDER:
      return {
        ...state,
        jobOrders: state.jobOrders.filter((jobOrder) => jobOrder.id !== action.payload),
      };
    case types.SEND_JOB_ORDER_EMAIL:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
}
