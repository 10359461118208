import axios from 'axios';
import * as types from './types';
import { returnErrors } from './errorActions';
import { CeilingFinish } from '../../types/interfaces';
import History from '../../components/common/History';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';


export const setCeilingFinishesLoading = () => ({ type: types.CEILING_FINISHES_LOADING });

export const getAllCeilingFinishes = () => (dispatch: Function, getState: Function) => {
  dispatch(setCeilingFinishesLoading());

  axios
    .get(`${configs.url.API_URL}/ceiling-finishes`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_ALL_CEILING_FINISHES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const getCeilingFinish = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setCeilingFinishesLoading());
  axios
    .get(`${configs.url.API_URL}/ceiling-finish/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_CEILING_FINISH,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const addCeilingFinish = (ceilingFinish: CeilingFinish) => (dispatch: Function, getState: Function) => {
  dispatch(setCeilingFinishesLoading());
  axios
    .post(`${configs.url.API_URL}/ceiling-finish`, ceilingFinish, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.ADD_CEILING_FINISH,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const updateCeilingFinish = (ceilingFinish: CeilingFinish) => (dispatch: Function, getState: Function) => {
  dispatch(setCeilingFinishesLoading());
  axios
    .put(`${configs.url.API_URL}/ceiling-finish`, ceilingFinish, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.UPDATE_CEILING_FINISH,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const deleteCeilingFinish = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setCeilingFinishesLoading());
  axios
    .delete(`${configs.url.API_URL}/ceiling-finish/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.DELETE_CEILING_FINISH,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};
