import axios from 'axios';
import * as types from './types';
import { returnErrors } from './errorActions';
import { Builder } from '../../types/interfaces';
import History from '../../components/common/History';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';


export const setBuildersLoading = () => ({ type: types.BUILDERS_LOADING });

export const getAllBuilders = () => (dispatch: Function, getState: Function) => {
  dispatch(setBuildersLoading());

  axios
    .get(`${configs.url.API_URL}/builders`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_ALL_BUILDERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const getBuilder = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setBuildersLoading());
  axios
    .get(`${configs.url.API_URL}/builder/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_BUILDER,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const addBuilder = (builder: Builder) => (dispatch: Function, getState: Function) => {
  dispatch(setBuildersLoading());
  axios
    .post(`${configs.url.API_URL}/builder`, builder, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.ADD_BUILDER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const updateBuilder = (builder: Builder) => (dispatch: Function, getState: Function) => {
  dispatch(setBuildersLoading());
  axios
    .put(`${configs.url.API_URL}/builder`, builder, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.UPDATE_BUILDER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const deleteBuilder = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setBuildersLoading());
  axios
    .delete(`${configs.url.API_URL}/builder/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.DELETE_BUILDER,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};
