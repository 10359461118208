import axios from 'axios';
import * as types from './types';
import { returnErrors } from './errorActions';
import { JobOrder, JobOrderEmail } from '../../types/interfaces';
import History from '../../components/common/History';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';


export const setJobOrdersLoading = () => ({ type: types.JOB_ORDERS_LOADING });

export const getAllJobOrders = () => (dispatch: Function, getState: Function) => {
  dispatch(setJobOrdersLoading());

  axios
    .get(`${configs.url.API_URL}/job-orders`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_ALL_JOB_ORDERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const getJobOrder = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setJobOrdersLoading());
  axios
    .get(`${configs.url.API_URL}/job-order/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_JOB_ORDER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const addJobOrder = (jobOrder: JobOrder) => (dispatch: Function, getState: Function) => {
  dispatch(setJobOrdersLoading());
  axios
    .post(`${configs.url.API_URL}/job-order`, jobOrder, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.ADD_JOB_ORDER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const updateJobOrder = (jobOrder: JobOrder) => (dispatch: Function, getState: Function) => {
  dispatch(setJobOrdersLoading());
  axios
    .put(`${configs.url.API_URL}/job-order`, jobOrder, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.UPDATE_JOB_ORDER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const deleteJobOrder = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setJobOrdersLoading());
  axios
    .delete(`${configs.url.API_URL}/job-order/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.DELETE_JOB_ORDER,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const sendJobOrderEmail = (jobOrderEmail: JobOrderEmail) => (dispatch: Function, getState: Function) => {
  // dispatch(setJobOrdersLoading());
  axios
    .post(`${configs.url.API_URL}/job-order/send-email`, jobOrderEmail, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.SEND_JOB_ORDER_EMAIL,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};
