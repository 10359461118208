import axios from 'axios';
import * as types from './types';
import { returnErrors } from './errorActions';
import { HouseType } from '../../types/interfaces';
import History from '../../components/common/History';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';


export const setHouseTypeLoading = () => ({ type: types.HOUSE_TYPES_LOADING });

export const getAllHouseTypes = () => (dispatch: Function, getState: Function) => {
  dispatch(setHouseTypeLoading());

  axios
    .get(configs.url.API_URL + '/house-types', authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_ALL_HOUSE_TYPES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const getHouseType = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setHouseTypeLoading());
  axios
    .get(`${configs.url.API_URL}/house-type/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.GET_HOUSE_TYPE,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const addHouseType = (houseType: HouseType) => (dispatch: Function, getState: Function) => {
  dispatch(setHouseTypeLoading());
  axios
    .post(configs.url.API_URL + '/house-type', houseType, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.ADD_HOUSE_TYPE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const updateHouseType = (houseType: HouseType) => (dispatch: Function, getState: Function) => {
  dispatch(setHouseTypeLoading());
  axios
    .put(configs.url.API_URL + '/house-type', houseType, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.UPDATE_HOUSE_TYPE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};

export const deleteHouseType = (id: number) => (dispatch: Function, getState: Function) => {
  dispatch(setHouseTypeLoading());
  axios
    .delete(`${configs.url.API_URL}/house-type/${id}`, authHeader(getState))
    .then((res) => {
      dispatch({
        type: types.DELETE_HOUSE_TYPE,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data.msg, err.response.status));
    });
};
