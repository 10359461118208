// Auth
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// Articles
export const ARTICLES_LOADING = 'ARTICLES_LOADING';
export const GET_ARTICLES = 'GET_ARTICLES';
export const ADD_ARTICLE = 'ADD_ARTICLE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';

// Users
export const USERS_LOADING = 'USERS_LOADING';
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

// Cities
export const CITIES_LOADING = 'CITIES_LOADING';
export const GET_ALL_CITIES = 'GET_ALL_CITIES';
export const GET_CITY = 'GET_CITY';
export const ADD_CITY = 'ADD_CITY';
export const UPDATE_CITY = 'UPDATE_CITY';
export const DELETE_CITY = 'DELETE_CITY';

// DeliveredBy
export const DELIVERED_BY_LOADING = 'DELIVERED_BY_LOADING';
export const GET_ALL_DELIVERED_BY = 'GET_ALL_DELIVERED_BY';
export const GET_DELIVERED_BY = 'GET_DELIVERED_BY';
export const ADD_DELIVERED_BY = 'ADD_DELIVERED_BY';
export const UPDATE_DELIVERED_BY = 'UPDATE_DELIVERED_BY';
export const DELETE_DELIVERED_BY = 'DELETE_DELIVERED_BY';

// Garage Finish
export const GARAGE_FINISHES_LOADING = 'GARAGE_FINISHES_LOADING';
export const GET_ALL_GARAGE_FINISHES = 'GET_ALL_GARAGE_FINISHES';
export const GET_GARAGE_FINISH = 'GET_GARAGE_FINISH';
export const ADD_GARAGE_FINISH = 'ADD_GARAGE_FINISH';
export const UPDATE_GARAGE_FINISH = 'UPDATE_GARAGE_FINISH';
export const DELETE_GARAGE_FINISH = 'DELETE_GARAGE_FINISH';

// Garage Stall
export const GARAGE_STALLS_LOADING = 'GARAGE_STALLS_LOADING';
export const GET_ALL_GARAGE_STALLS = 'GET_ALL_GARAGE_STALLS';
export const GET_GARAGE_STALL = 'GET_GARAGE_STALL';
export const ADD_GARAGE_STALL = 'ADD_GARAGE_STALL';
export const UPDATE_GARAGE_STALL = 'UPDATE_GARAGE_STALL';
export const DELETE_GARAGE_STALL = 'DELETE_GARAGE_STALL';

// House Type
export const HOUSE_TYPES_LOADING = 'HOUSE_TYPES_LOADING';
export const GET_ALL_HOUSE_TYPES = 'GET_ALL_HOUSE_TYPES';
export const GET_HOUSE_TYPE = 'GET_HOUSE_TYPE';
export const ADD_HOUSE_TYPE = 'ADD_HOUSE_TYPE';
export const UPDATE_HOUSE_TYPE = 'UPDATE_HOUSE_TYPE';
export const DELETE_HOUSE_TYPE = 'DELETE_HOUSE_TYPE';

// Options
export const OPTIONS_LOADING = 'OPTIONS_LOADING';
export const GET_ALL_OPTIONS = 'GET_ALL_OPTIONS';
export const GET_OPTION = 'GET_OPTION';
export const ADD_OPTION = 'ADD_OPTION';
export const UPDATE_OPTION = 'UPDATE_OPTION';
export const DELETE_OPTION = 'DELETE_OPTION';

// User Types
export const USER_TYPES_LOADING = 'USER_TYPES_LOADING';
export const GET_ALL_USER_TYPES = 'GET_ALL_USER_TYPES';
export const GET_USER_TYPE = 'GET_USER_TYPE';
export const ADD_USER_TYPE = 'ADD_USER_TYPE';
export const UPDATE_USER_TYPE = 'UPDATE_USER_TYPE';
export const DELETE_USER_TYPE = 'DELETE_USER_TYPE';

// Vaults
export const VAULTS_LOADING = 'VAULTS_LOADING';
export const GET_ALL_VAULTS = 'GET_ALL_VAULTS';
export const GET_VAULT = 'GET_VAULT';
export const ADD_VAULT = 'ADD_VAULT';
export const UPDATE_VAULT = 'UPDATE_VAULT';
export const DELETE_VAULT = 'DELETE_VAULT';

// Rate Plan
export const RATE_PLAN_LOADING = 'RATE_PLAN_LOADING';
export const GET_ALL_RATE_PLAN = 'GET_ALL_RATE_PLAN';
export const GET_RATE_PLAN = 'GET_RATE_PLAN';
export const ADD_RATE_PLAN = 'ADD_RATE_PLAN';
export const UPDATE_RATE_PLAN = 'UPDATE_RATE_PLAN';
export const DELETE_RATE_PLAN = 'DELETE_RATE_PLAN';
export const LOAD_RATE_PLAN_JOBS = 'LOAD_RATE_PLAN_JOBS';

// Builder
export const BUILDERS_LOADING = 'BUILDERS_LOADING';
export const GET_ALL_BUILDERS = 'GET_ALL_BUILDERS';
export const GET_BUILDER = 'GET_BUILDER';
export const ADD_BUILDER = 'ADD_BUILDER';
export const UPDATE_BUILDER = 'UPDATE_BUILDER';
export const DELETE_BUILDER = 'DELETE_BUILDER';

// Ceiling Finish
export const CEILING_FINISHES_LOADING = 'CEILING_FINISHES_LOADING';
export const GET_ALL_CEILING_FINISHES = 'GET_ALL_CEILING_FINISHES';
export const GET_CEILING_FINISH = 'GET_CEILING_FINISH';
export const ADD_CEILING_FINISH = 'ADD_CEILING_FINISH';
export const UPDATE_CEILING_FINISH = 'UPDATE_CEILING_FINISH';
export const DELETE_CEILING_FINISH = 'DELETE_CEILING_FINISH';

// JobOrder
export const JOB_ORDERS_LOADING = 'JOB_ORDERS_LOADING';
export const GET_ALL_JOB_ORDERS = 'GET_ALL_JOB_ORDERS';
export const GET_JOB_ORDER = 'GET_JOB_ORDER';
export const ADD_JOB_ORDER = 'ADD_JOB_ORDER';
export const UPDATE_JOB_ORDER = 'UPDATE_JOB_ORDER';
export const DELETE_JOB_ORDER = 'DELETE_JOB_ORDER';
export const SEND_JOB_ORDER_EMAIL = 'SEND_JOB_ORDER_EMAIL';

// BillingItem
export const BILLING_ITEMS_LOADING = 'BILLING_ITEMS_LOADING';
export const GET_ALL_BILLING_ITEMS = 'GET_ALL_BILLING_ITEMS';
export const GET_BILLING_ITEM = 'GET_BILLING_ITEM';
export const ADD_BILLING_ITEM = 'ADD_BILLING_ITEM';
export const UPDATE_BILLING_ITEM = 'UPDATE_BILLING_ITEM';
export const DELETE_BILLING_ITEM = 'DELETE_BILLING_ITEM';

// HouseLevelType
export const HOUSE_LEVEL_TYPES_LOADING = 'HOUSE_LEVEL_TYPES_LOADING';
export const GET_ALL_HOUSE_LEVEL_TYPES = 'GET_ALL_HOUSE_LEVEL_TYPES';
export const GET_HOUSE_LEVEL_TYPE = 'GET_HOUSE_LEVEL_TYPE';
export const ADD_HOUSE_LEVEL_TYPE = 'ADD_HOUSE_LEVEL_TYPE';
export const UPDATE_HOUSE_LEVEL_TYPE = 'UPDATE_HOUSE_LEVEL_TYPE';
export const DELETE_HOUSE_LEVEL_TYPE = 'DELETE_HOUSE_LEVEL_TYPE';
