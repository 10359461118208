import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import _ from 'lodash';
import Select from 'react-select';
import * as userActions from '../redux/actions/userActions';
import * as userTypeActions from '../redux/actions/userTypeActions';
import PropTypes from 'prop-types';
import { UserReduxProps, UserList, ExistingUser, UserType, Target } from '../types/interfaces';


const UserPage = ({ users, userTypes, actions }: UserList) => {
  useEffect(() => {
    console.log('calling users', users);
    console.log('calling users', actions);
    actions.getUsers();
    actions.getAllUserTypes();
  }, [actions.getUsers, actions.getAllUserTypes]);

  const defaultState: ExistingUser = {
    id: 0, name: '', firstname: '', email: '', password: '', phone: '', userTypes: [],
  };

  const [formData, setFormData] = useState(defaultState);
  const [isPassword, setIsPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleAdd = () => {
    // const itemsList = [...items, { id: uuidv4(), title: 'article 5', body: 'body 5' }];
    // setItems((items) => {
    //   items: [...items, { id: uuidv4(), title: 'article 5', body: 'body 5' }];
    // });
    // console.log(items);

    // setItems([ ...items, { id: uuidv4(), title: 'article 5', body: 'body 5' } ]);
  };

  const clearData = () => {
    setSubmitted(false);
    setFormData({ ...defaultState });
    setIsPassword(false);
  };

  const { users: usersData } = users;
  const { userTypes: userTypesData } = userTypes;
  const handleDelete = (e: any, id: number) => {
    e.preventDefault();
    console.log(id);
    actions.deleteUser(id);
    // const itemsList = items.filter(item => item.id !== id);
    // setItems([ ...itemsList ]);
  };

  const handleEdit = (e: any, user: ExistingUser) => {
    e.preventDefault();
    console.log('yes working');
    console.log(user);
    setFormData({ ...defaultState, ...user });
    console.log(formData);
  };

  const onUserTypeChange = (value: any) => {
    console.log(value);
    if (value === null) {
      setFormData({ ...formData, userTypes: [] });
    } else {
      setFormData({ ...formData, userTypes: [...value] });
    }

    // console.log([...e.target.selectedOptions].map((o) => o.value));
    // setFormData({ ...formData, userTypes: [...e.target.selectedOptions].map((o) => o.value) });
  };
  const onFormChange = (e: Target) => {
    console.log(e);
    console.log(e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };

  const onPasswordCheckChange = (e: Target) => {
    setIsPassword(isPassword ? false : true);
    console.log('yes changing ', isPassword);
  }


  const onSelect = (selectedList: any, selectedItem: any) => {
    //
    console.log(selectedItem);
    console.log(selectedList);
    const newList = [...selectedList, selectedItem];
    setFormData({ ...formData, userTypes: [...selectedList, ...selectedItem] });

    // const newList = people.filter((item) => item.id !== idToRemove);
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    //
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSubmitted(true);
    console.log('form submitted');
    console.log(formData);
    // const { title, body } = formData;
    // const newUser = {
    //   ...formData,
    //   // id: 1,
    //   // title,
    //   // body,
    //   // title: formData.name,
    //   // body: formData.body
    // };
    // console.log(newUser);
    if (formData.name && formData.email && formData.userTypes.length && (!isPassword || (formData.password && isPassword))) {
      if (!formData.id) {
        console.log(formData.id, 'add user');
        console.log(formData);
        actions.addUser(formData);
      } else {
        console.log(formData.id, 'update user');
        actions.updateUser(formData);
        console.log(formData);
      }
    }
  };

  return (
    <>
      <div className="clear pad-40" />

      <div className="container">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-3">
                  <h3>
                    Users
                    <br />
                    <small>Manage users & details</small>
                  </h3>
                </div>
                <div className="col-md-9 mt-20 text-right">
                  {/* <button type="button" className="btn btn-info mr-5">
                    <i className="fas fa-arrow-circle-left mr-5" />
                    Return to Dashboard
                  </button> */}
                  {/* <button type="button" className="btn btn-primary">
                    <i className="fas fa-save mr-5" />
                    Save
                  </button> */}
                </div>
              </div>
            </div>

            <div className="card-body">
              <form className="form-horizontal" onSubmit={(e) => handleSubmit(e)}>
                <h4 className="text_blue">
                  User Details
                </h4>
                <div className="clear pad-5" />
                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Name :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input type="text" id="name" name="name" value={formData.name || ''} onChange={(e) => onFormChange(e)} className={`form-control ${submitted && !formData.name ? 'ap-required' : ''}`} />
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Email :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input type="text" id="email" name="email" value={formData.email || ''} onChange={(e) => onFormChange(e)} className={`form-control ${submitted && !formData.email ? 'ap-required' : ''}`} />
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Phone :
                    </label>
                    <div className="col-md-9">
                      <input type="text" className="form-control" id="phone" value={formData.phone || ''} name="phone" onChange={(e) => onFormChange(e)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      User Type :
                    </label>
                    <div className="col-md-9">
                      <Select
                        isMulti
                        options={userTypesData}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        // onChange={() => onUserTypeChange}
                        value={formData.userTypes}
                        onChange={(value) => onUserTypeChange(value)}
                        className={`${submitted && !formData.userTypes.length ? 'ap-required' : ''}`}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6 mb-20 text-left">
                    <div className="col-md-3 text-left">

                    </div>
                    <div className="col-md-9">
                      <label className="col-md-12 control-label text-left">
                        <input type="checkbox" name="isPassword" onChange={(e) => onPasswordCheckChange(e)} />
                        Create login credentials for this user
                    </label>
                    </div>
                  </div>
                </div>

                <div className={!isPassword ? 'row hidden' : 'row'}>
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Password :
                      <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <input type="password" id="password" name="password" value={formData.password || ''} onChange={(e) => onFormChange(e)} className={`form-control ${submitted && !formData.password && isPassword ? 'ap-required' : ''}`} />
                    </div>
                  </div>
                </div>




                <div className="row">
                  <div className="col-md-12 mt-20 text-right">
                    <button type="button" className="btn btn-info mr-5" onClick={() => clearData()}>
                      {/* <i className="fas fa-arrow-circle-left mr-5" /> */}
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      <i className="fas fa-save mr-5" />
                      Save
                    </button>
                  </div>
                </div>

                <hr />
                {/* <div className="clear pad-15" /> */}

                {/* <div className="row">
                  <div className="col-md-6 col-md-offset-3">
                    <div className="row">
                      <div className="col-sm-6 mb-5">
                        <button type="button" className="btn btn-info btn-block btn-lg">
                          <i className="fas fa-arrow-circle-left mr-5" />
                          Return to Schedule
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button type="submit" className="btn btn-primary btn-block btn-lg">
                          <i className="fas fa-save mr-5" />
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </form>

              <h4 className="text_blue">
                Users
              </h4>
              <div className="clear pad-5" />

              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      {/* <th>
                        <input type="checkbox" />
                      </th> */}
                      <th>
                        Name
                      </th>
                      <th>
                        Email
                      </th>
                      <th>
                        User Type
                      </th>
                      <th>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersData.length > 0 ? usersData.map((user) => (
                      <tr key={user.id}>
                        {/* <td>
                          <input type="checkbox" />
                        </td> */}
                        <td>
                          <strong>{user.name}</strong>
                        </td>
                        <td>
                          {user.email}
                        </td>
                        <td>
                          {user.userTypes.length ? user.userTypes.map(userType => userType.name).join(', ') : ''}
                        </td>
                        <td>
                          <a
                            href="#"
                            title="Edit"
                            className="text_grey_d"
                            onClick={(e) => handleEdit(e, user)}
                          >
                            <i className="fa fa-edit fa-lg" />
                          </a>
                          <a
                            href="#"
                            title="Edit"
                            className="text_red"
                            onClick={(e) => handleDelete(e, user.id)}
                          >
                            <i className="fa fa-times-circle fa-lg" />
                          </a>
                        </td>
                      </tr>
                    )) : (
                        <tr>
                          <td colSpan={5} className="text-center">
                            No record found.
                        </td>
                        </tr>
                      )}
                  </tbody>
                </table>
                {usersData.length < 1}
                {/* <ul className="pagination center-block">
                  <li><a href="#">«</a></li>
                  <li><a href="#">1</a></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#">4</a></li>
                  <li><a href="#">5</a></li>
                  <li><a href="#">»</a></li>
                </ul> */}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UserPage.propTypes = {
  // users: PropTypes.object.isRequired,
  // actions: PropTypes.func.isRequired
};


const mapStateToProps = (state: UserReduxProps) => ({
  users: state.users,
  userTypes: state.userTypes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    getUsers: bindActionCreators(userActions.getUsers, dispatch),
    addUser: bindActionCreators(userActions.addUser, dispatch),
    updateUser: bindActionCreators(userActions.updateUser, dispatch),
    deleteUser: bindActionCreators(userActions.deleteUser, dispatch),
    getAllUserTypes: bindActionCreators(userTypeActions.getAllUserTypes, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPage);
