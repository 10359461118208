import * as types from '../actions/types';
import { Action, ExistingUser } from '../../types/interfaces';


const initialState = {
  users: [],
  loading: false,
};

interface State {
  users: ExistingUser[];
}


export default function (state: State = initialState, action: Action) {
  switch (action.type) {
    case types.USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case types.ADD_USER:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case types.UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) => {
          return user.id !== action.payload.id ? user : {
            ...user,
            name: action.payload.name,
            firstname: action.payload.firstname,
            email: action.payload.email,
            phone: action.payload.phone,
          };
        }),
      };
    case types.DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    default:
      return {
        ...state,
      };
  }
}
