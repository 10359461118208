import * as types from '../actions/types';
import { Action, BillingItem } from '../../types/interfaces';


const initialState = {
  billingItems: [],
  loading: false,
};

interface State {
  billingItems: BillingItem[];
}


export default function (state: State = initialState, action: Action) {
  switch (action.type) {
    case types.BILLING_ITEMS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_BILLING_ITEMS:
      return {
        ...state,
        billingItems: action.payload,
        loading: false,
      };
    case types.ADD_BILLING_ITEM:
      return {
        ...state,
        billingItems: [action.payload, ...state.billingItems],
      };
    case types.UPDATE_BILLING_ITEM:
      return {
        ...state,
        billingItems: state.billingItems.map((billingItem) => {
          return billingItem.id !== action.payload.id ? billingItem : {
            ...billingItem,
            name: action.payload.name,
            status: action.payload.status,
          };
        }),
      };
    case types.DELETE_BILLING_ITEM:
      return {
        ...state,
        billingItems: state.billingItems.filter((billingItem) => billingItem.id !== action.payload),
      };
    default:
      return {
        ...state,
      };
  }
}
