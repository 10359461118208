import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { INavbar, IAuthReduxProps } from '../../types/interfaces';


import Logout from '../auth/Logout';

const Navbar = ({ auth }: INavbar) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const authLinks = (
    <>
      <nav className="navbar" role="navigation">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#main-menu">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
        </div>

        <div className="collapse navbar-collapse" id="main-menu">
          <ul className="nav nav-justified navs">
            <li className="active"><a href="/">Home</a></li>
            {/* <NavLink to={'/users'} activeClassName="active">
              Trending
            </NavLink> */}
            {/* <li><a href="#">New JIO</a></li> */}
            <li><a href="/job-orders">New JIO</a></li>
            <li><a href="/schedules">Overall Schedule</a></li>
            <li><a href="#">Hanger & Taper</a></li>
            <li><a href="#">Sprayer & Sander</a></li>
            <li><a href="#">Garage</a></li>
            <Logout />
            {/* <li><a href="#">Logout</a></li> */}
          </ul>
        </div>
      </nav>
    </>
  );

  const guestLinks = (
    <>
    </>
  );

  return (
    <>
      {auth && auth.isAuthenticated ? authLinks : guestLinks}
    </>
  );
};

const mapStateToProps = (state: IAuthReduxProps) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Navbar);
