import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import PropTypes from 'prop-types';
import { authHeader } from '../../helpers/authHeader';
import { configs } from '../../types/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  InvoicesList,
} from '../../types/interfaces';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '500px',
    overlfow: 'scroll',
    width: '50%',
  }
};
Modal.setAppElement('#root');


const InvoicesPage = ({ invoices, actions }: InvoicesList, getState: Function) => {
  useEffect(() => {
    const url = configs.url.API_URL + '/jobs';

    axios.get(url, authHeader(getState))
      .then(response => {
        setJobState(JSON.parse(JSON.stringify(response.data)));
        setLoadingState(false);
        console.log(response.data);
      });

  }, []);

  const defaultState: any = {
    job_id: '',
  };

  const [modalFormData, setModalFormData] = useState([]);
  const [formData, setFormData] = useState(defaultState);
  const [jobData, setJobData] = useState('');
  const [jobsData, setJobState] = useState([]);
  const [loading, setLoadingState] = useState(true);
  const [levelsData, setLevelsData] = useState([]);
  const [invoiceLevelsData, setInvoiceLevelsData] = useState([] as any);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [formLevelData, setLevelFormData] = useState(defaultState);
  const [rpSampleData, setRpSampleData] = useState([]);

  const handleSubmit = (e: any) => {

  };
  const onFormChange = (e: any) => {

  };
  const onStatusChange = (e: any) => {
    console.log(e.target.value);
    setFormData({ ...formData, [e.target.name]: parseInt(e.target.value, 10) });
    axios.get(`${configs.url.API_URL}/invoices/${e.target.value}`, authHeader(getState))
      .then((res) => {
        const serverResponse = JSON.parse(JSON.stringify(res.data));
        setLevelsData(serverResponse);
        console.log(levelsData);
      }).catch((err) => {
        console.log(err);
      });
  };
  const clearData = () => {

  };
  const handleEdit = (e: any, leveldata: any) => {

  };
  const onLevelStatusChange = (e: any, contractor_id: any) => {
    e.preventDefault();
    const obj = {
      type_id: formData.job_id,
      contractor_id: contractor_id,
      level_id: parseInt(e.target.value, 10),
    };
    axios.post(configs.url.API_URL + '/update_contractor_level', obj, authHeader(getState))
      .then((res) => {
        const serverResponse = JSON.parse(JSON.stringify(res.data));
        console.log(serverResponse);
        if (serverResponse.status) {
          let currentValue: any = [...levelsData];
          for (let i = 0; i < currentValue.length; i++) {
            if (currentValue[i].contractor_id === contractor_id) {
              currentValue[i].level_id = obj.level_id;
            }
          }
          setLevelsData(currentValue);
          toast.success(serverResponse.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch((err) => {
        console.log(err);
      });
  };
  const assignRatePlan = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setJobData('');
    setInvoiceLevelsData([]);
  };

  const afterOpenModal = () => {

    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  };
  const updateModalLevelData = (e: any, dt: any) => {
    e.preventDefault();
    const obj = {
      type_id: jobData,
      level_id: dt.level_name,
      rp_id: e.target.value,
    };
    axios.post(configs.url.API_URL + '/update_invoice_levels', obj, authHeader(getState))
      .then((res) => {
        const serverResponse = JSON.parse(JSON.stringify(res.data));
        console.log(serverResponse);
        if (serverResponse.status) {
          let currentValue: any = [...invoiceLevelsData];
          // let keys = Object.keys(currentValue);
          for (let i = 0; i < currentValue.length; i++) {
            if (currentValue[i].level_name === dt.level_name) {
              currentValue[i].rp_id = obj.rp_id;
            }
          }
          setInvoiceLevelsData(currentValue);
          toast.success(serverResponse.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch((err) => {
        console.log(err);
      });
  };
  const onJobChange = (e: any) => {
    setJobData(e.target.value);
    axios.get(`${configs.url.API_URL}/invoicelevels/${e.target.value}`, authHeader(getState))
      .then((res) => {
        const serverResponse = JSON.parse(JSON.stringify(res.data));
        setRpSampleData(serverResponse[0].rateplans);
        setInvoiceLevelsData(serverResponse);
      }).catch((err) => {
        console.log(err);
      });
  };
  const deleteLevelRow = (e: any, delid: any) => {
    e.preventDefault();
    console.log(delid);
    if (window.confirm('Do you really want to delete this!')) {

      axios.get(`${configs.url.API_URL}/delete_rateplan_levels/${delid}`, authHeader(getState))
        .then((res) => {
          const serverResponse = JSON.parse(JSON.stringify(res.data));
          if (serverResponse.status) {
            toast.success(serverResponse.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            var index = JSON.parse(JSON.stringify(invoiceLevelsData)).findIndex((e: any) => e.delid == delid);
            //copy array
            var newAray = JSON.parse(JSON.stringify(invoiceLevelsData)).slice();
            //delete element by index
            newAray.splice(index, 1);
            setInvoiceLevelsData(newAray);
          }

        }).catch((err) => {
          console.log(err);
        });
    }
  };
  const addNewLevel = (e: any) => {
    e.preventDefault();
    axios.post(configs.url.API_URL + '/add_new_invoice_level',
      {
        type_id: jobData,
        level_name: invoiceLevelsData[invoiceLevelsData.length - 1].level_name,
      },
      authHeader(getState)
    ).then((res) => {
      const serverResponse = JSON.parse(JSON.stringify(res.data));
      console.log(serverResponse);
      if (serverResponse.status) {
        toast.success(serverResponse.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(serverResponse.objData[0]);
        // let obj = JSON.parse(serverResponse.objData[0]);
        setInvoiceLevelsData(serverResponse.objData);
        // invoiceLevelsData.push(serverResponse.objData[0]);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="clear pad-40" />
      <div className="container">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-3">
                  <h3>
                    Invoices
                                    <br />
                    <small>Manage Invoices & details</small>
                  </h3>
                </div>
                <div className="col-md-9 mt-20 text-right">

                </div>
              </div>
            </div>

            <div className="card-body">
              <form className="form-horizontal" onSubmit={(e) => handleSubmit(e)}>
                <h4 className="text_blue">
                  Invoice Details
                                </h4>
                <div className="clear pad-5" />
                <div className="row">
                  <div className="form-group col-md-6 mb-20">
                    <label className="col-md-3 control-label">
                      Job Type :
                                        <span className="text_red">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name="job_id"
                        value={formData.job_id || ''}
                        onChange={(e) => onStatusChange(e)}
                      >
                        <option value={0}>select...</option>
                        {!loading ? jobsData.map((jd: any, i: any) => (
                          <option key={i} value={jd?.id}>{jd?.job}</option>
                        )) : (
                            <option value={0}>select...</option>
                          )}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-20">
                    <button type="button" className="btn btn-primary" onClick={() => assignRatePlan()}>
                      <i className="fas fa-arrow-circle-left mr-5" />
                                            Assign Rate Plan
                                        </button>
                  </div>
                </div>
                <hr />
              </form>

              <h4 className="text_blue">
                Invoices
                            </h4>
              <div className="clear pad-5" />

              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      {/* <th>
                        <input type="checkbox" />
                      </th> */}
                      <th>
                        Sub Contractor Name
                      </th>
                      <th>
                        Sub Contractor Levels
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {levelsData.length > 0 ? levelsData.map((leveldata: any) => (
                      <tr key={leveldata.contractor_id}>
                        {/* <td>
                          <input type="checkbox" />
                        </td> */}
                        <td>
                          <strong>{leveldata.contractor_name}</strong>
                        </td>
                        <td>
                          <select
                            className="form-control"
                            name="level_id"
                            value={leveldata.level_id}
                            onChange={(e) => onLevelStatusChange(e, leveldata.contractor_id)}
                          >
                            <option value={0}>select...</option>
                            {leveldata.levels.length > 0 ? leveldata.levels.map((jd: any, i: any) => (
                              <option key={i} value={jd?.id}>{jd?.level_name} </option>
                            )) : (
                                <option value={0}>select...</option>
                              )}
                          </select>
                        </td>
                      </tr>
                    )) : (
                        <tr>
                          <td colSpan={5} className="text-center">
                            No record found.
                           </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Assign Rate Plan"
      >
        <button onClick={closeModal}>close</button>
        <br /><br /><div>Job Type</div>
        <select
          className="form-control"
          name="job_id"
          value={jobData || ''}
          onChange={(e) => onJobChange(e)}
        >
          <option value={0}>select...</option>
          {!loading ? jobsData.map((jd: any, i: any) => (
            <option key={i} value={jd?.id}>{jd?.job}</option>
          )) : (
              <option value={0}>select...</option>
            )}
        </select>
        <br /><br />
        <table className="modaltbl">
          <thead>
            <tr>
              <th>
                Level
                            </th>
              <th>
                Rate Plan
                            </th>
              <th>
                Action
                            </th>
            </tr>
          </thead>
          <tbody>
            {invoiceLevelsData.length > 0 ? invoiceLevelsData.map((dt: any, i: any) => (
              <tr key={i}>
                <td>{dt.level_name}</td>
                <td>
                  <select
                    className="form-control"
                    name="job_id"
                    value={dt.rp_id || ''}
                    onChange={(e) => { updateModalLevelData(e, dt) }}
                  >
                    <option value={0}>select...</option>
                    {dt.rateplans.length > 0 ? dt.rateplans.map((jd: any, i: any) => (
                      <option key={i} value={jd?.id}>{jd?.rate_plan_name}</option>
                    )) : (
                        <option value={0}>select...</option>
                      )}
                  </select>
                </td>
                <td>
                  <button type="button" className="btn btn-danger right-10" onClick={(e) => deleteLevelRow(e, dt.delid)}>
                    Delete
                                    </button>
                </td>
              </tr>
            )) : (
                <tr>
                  <td colSpan={3}>Sorry! no data available...</td>
                </tr>
              )}
          </tbody>
        </table>
        <div className="pull-center top10">
          <button type="button" className="btn btn-info mr-5" onClick={(e) => addNewLevel(e)}>
            Add New Level
                    </button>
        </div>
      </Modal>
    </>
  );
};

InvoicesPage.propTypes = {

};

export default connect(

)(InvoicesPage);
